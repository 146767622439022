import React from "react";
import { Link } from "react-router-dom";


function Menu() {

    return (


        <div className="principal">
            <div className="menu">

                <ul>
                    <Link to="/"> <li>Home</li></Link>
                    <Link to="/ofertas"> <li>Ofertas</li></Link>
                    <Link to="/hortifruti"> <li>Hortifruti</li></Link>
                    <Link to="/casa-de-carnes"> <li>Casa de Carnes</li></Link>
                    <Link to="/contato"> <li>Contato</li></Link>
                    <Link to="/trabalhe-conosco"> <li>Trabalhe Conosco</li></Link>
                </ul>

            </div>
        </div>


    )


}

export default Menu;