import React, { useState } from "react";
import { useNavigate, } from 'react-router-dom';
import { RiAdminFill } from 'react-icons/ri'
import { getAuth, updatePassword, signInWithEmailAndPassword } from "firebase/auth";
import { msgAviso } from "./sweet";

import { Navbar, Nav, Container, NavDropdown, Modal, Form, Button } from 'react-bootstrap'


function Navegacao() {

    const Navigate = useNavigate()

    // const [loading, setLoading] = useState(false)

    const [modalSenha, setModalSenha] = useState('')
    const [senhaAtual, setSenhaAtual] = useState('')
    const [senhaNova, setSenhaNova] = useState('')
    const [senhaConfirma, setSenhaConfirma] = useState('')

    function Sair() {
        Navigate("/intranet")
        localStorage.removeItem("logado");
        localStorage.removeItem("usuario_logado");
    }

    async function atualizaSenha() {

        // setLoading(true)

        const auth = getAuth();
        const usuarioAtual = auth.currentUser;
        const emailAtual = auth.currentUser.email;

        console.log(auth)

        signInWithEmailAndPassword(auth, emailAtual, senhaAtual)
            .then((userCredential) => {

                // setLoading(false)

                if (senhaNova === senhaConfirma) {

                    updatePassword(usuarioAtual, senhaConfirma).then(() => {
                        msgAviso('Parabéns', 'Senha Atualizada Com Sucesso!!', 'success', 'ok');
                        Sair()
                    }).catch((error) => {
                        msgAviso('Falha ao Atualizar a Senha', error, 'error', 'ok');
                    });

                } else {
                    msgAviso('Atenção', 'Senha Nova Está Diferente da Confirmação!', 'error', 'ok');
                }

            })
            .catch((error) => {
                // setLoading(false)
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log('Erro: ' + errorCode + ' - ' + errorMessage)
                msgAviso('Atenção', 'Senha Atual Inválida!', 'error', 'ok');
            })

    }

    return (
        <>
            <Navbar className="barra-dash" expand="lg">
                <Container>
                    <Navbar.Brand className="titulo-dash" onClick={() => { Navigate("/intranet/dashboard") }}><p>< RiAdminFill size={24} color='#ffffff' className="icone-nav" />Magia Floripa</p></Navbar.Brand>

                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link onClick={() => { Navigate("/intranet/dashboard") }}>Dashboard</Nav.Link>
                            <NavDropdown title="Conteúdo" id="basic-nav-dropdown">
                                <NavDropdown.Item onClick={() => { Navigate("/intranet/banners") }}>Slides</NavDropdown.Item>
                                <NavDropdown.Item onClick={() => { Navigate("/intranet/newsletter") }}>Páginas</NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown title="Newsletter" id="basic-nav-dropdown">
                                <NavDropdown.Item onClick={() => { Navigate("/intranet/newsletter") }}>Gerenciar</NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown title="Recrutamento" id="basic-nav-dropdown">
                                <NavDropdown.Item onClick={() => { Navigate("/intranet/recrutamento/cadastro-vagas") }}>Quadro de Vagas</NavDropdown.Item>
                                <NavDropdown.Item onClick={() => { Navigate("/intranet/recrutamento/candidatos") }}>Candidatos</NavDropdown.Item>
                                <NavDropdown.Item onClick={() => { Navigate("/intranet/newsletter") }}>Relatórios</NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown title="Relatórios" id="basic-nav-dropdown">
                                <NavDropdown.Item onClick={() => { Navigate("/rel-vencimentos") }}>Vencimentos</NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown title="Configurações" id="basic-nav-dropdown">
                                <NavDropdown.Item onClick={() => { setModalSenha(true) }}>Alterar Minha Senha</NavDropdown.Item>
                                <NavDropdown.Divider></NavDropdown.Divider>
                                <NavDropdown.Item onClick={() => { Navigate("/intranet/usuarios") }}>Usuários</NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link onClick={() => { Sair() }}>Sair</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>

            </Navbar>





            <Modal //Modal Alteração de senha
                show={modalSenha}

            // dialogClassName="modal-75"
            >
                <Modal.Header>
                    <Modal.Title>Alteração de Senha</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Senha Atual</Form.Label>
                            <Form.Control type="password" placeholder="Senha Atual" value={senhaAtual} onChange={(e) => { setSenhaAtual(e.target.value) }} />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Nova Senha</Form.Label>
                            <Form.Control type="password" placeholder="Nova Senha" value={senhaNova} onChange={(e) => { setSenhaNova(e.target.value) }} />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Redigite a Nova Senha</Form.Label>
                            <Form.Control type="password" placeholder="Nova Senha" value={senhaConfirma} onChange={(e) => { setSenhaConfirma(e.target.value) }} />
                        </Form.Group>

                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { setModalSenha(false) }}>Fechar</Button>
                    <Button variant="success" onClick={() => { atualizaSenha() }}>Atualizar</Button>
                </Modal.Footer>
            </Modal>


        </>

    )
}

export default Navegacao;