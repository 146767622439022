import React, { useEffect } from "react";

import { Container, Card, Row, Col } from 'react-bootstrap'
import { useNavigate, } from 'react-router-dom';

import Navegacao from "../../components/navbar";

function Dashboard() {

    const navigate = useNavigate();

    useEffect(() => {
        const logado = localStorage.getItem("logado");
        if (logado !== 'S') {
            navigate("/intranet")
        }
    }, [navigate])


    return (

        <>

            <Navegacao />

            <Container >
                <br />
                <Row>
                    <h1>Dashboard</h1>
                </Row>


                <div className="box-cards">


                    <Row>
                        <Col>
                            <Card style={{ width: '15rem', backgroundColor: 'green' }}>
                                <Card.Body>
                                    <Card.Title style={{ color: 'white' }}>Clientes Cadastrados</Card.Title>
                                    <Card.Text style={{ fontSize: 48, color: 'white', textAlign: 'right' }} >
                                        18
                                    </Card.Text>
                                </Card.Body>
                            </Card>

                        </Col>
                        <Col>
                            <Card style={{ width: '15rem', backgroundColor: 'olive' }}>
                                <Card.Body>
                                    <Card.Title style={{ color: 'white' }}>Clientes Cadastrados</Card.Title>
                                    <Card.Text style={{ fontSize: 48, color: 'white', textAlign: 'right' }} >
                                        18
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col>
                            <Card style={{ width: '15rem', backgroundColor: 'purple' }}>
                                <Card.Body>
                                    <Card.Title style={{ color: 'white' }}>Clientes Cadastrados</Card.Title>
                                    <Card.Text style={{ fontSize: 48, color: 'white', textAlign: 'right' }} >
                                        18
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col>
                            <Card style={{ width: '15rem', backgroundColor: 'brown' }}>
                                <Card.Body>
                                    <Card.Title style={{ color: 'white' }}>Clientes Cadastrados</Card.Title>
                                    <Card.Text style={{ fontSize: 48, color: 'white', textAlign: 'right' }} >
                                        18
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>


                </div>

                <br />
                <br />
                <br />

                {/* <AnalyticsDashboard
                    authOptions={{ clientId: "843598297349-anorbt418e7glv9p9budt0l90i2o8jkg.apps.googleusercontent.com" }}
                    renderCharts={(gapi, viewId) => {
                        return (
                            <div>

                                <ActiveUsersChart
                                    gapi={gapi}
                                    viewId={viewId}
                                    days={28}
                                    activeUserDays={7}
                                    options={{
                                        title: `Acessos (7 Dias)`,
                                    }}
                                />
                            </div>
                        );
                    }}
                /> */}


            </Container>


        </>



    )
}

export default Dashboard;