import React from "react";
import { Link } from "react-router-dom";

function Footer() {

    return (


        <div id='footer'>



            <div className="linha-footer-box">


                <div className="conteudo-footer">


                    <div className="box-footer">
                        <h3>Links Importantes</h3>
                        <ul className="lista-footer">
                            <a href="https://portaldofornecedor.rpinfo.com.br/magia_lagoa" target="_blank" rel="noreferrer"><li className="item-footer">Portal do Fornecedor</li></a>
                            <Link to="/intranet"><li className="item-footer">Intranet</li></Link>
                            <a href="https://mail.google.com" target="_blank" rel="noreferrer"><li className="item-footer">Webmail</li></a>
                        </ul>

                    </div>

                    <div className="box-footer">
                        <h3>Redes Sociais</h3>
                        <ul className="lista-footer">
                            <a href="https://www.facebook.com/Supermercado-Magia-Floripa-190331157663341" target="_blank" rel="noreferrer"><li className="item-footer">Facebook</li></a>
                            <a href="https://www.instagram.com/super.magia.floripa/a" target="_blank" rel="noreferrer"><li className="item-footer">Instagram</li></a>
                        </ul>

                    </div>

                    <div className="box-footer">
                        <h3>Compre On-Line</h3>
                        <ul className="lista-footer">
                            <a href="https://www.sitemercado.com.br/supermercadomagia/florianopolis-loja-lagoa-lagoa-da-conceicao-r-manoel-severino-de-oliveira" target="_blank" rel="noreferrer"><li className="item-footer">E-Commerce</li></a>
                            <a href="https://www.ifood.com.br/delivery/florianopolis-sc/supermercados-magia-floripa-lagoa-da-conceicao" target="_blank" rel="noreferrer"><li className="item-footer">iFood</li></a>
                            <a href="https://wa.me/554832151900" target="_blank" rel="noreferrer"><li className="item-footer">WhatsApp</li></a>
                        </ul>

                    </div>

                </div>
            </div>


            <div className="linha-footer">
                <div className="conteudo-linha-footer">
                    <p>Comercio De Alimentos Magia Lagoa Ltda <br />
                        CNPJ: 04.549.425/0001-05 - Telefone: (48) 3215-1900<br />
                        R Manoel Severino de Oliveira, 639, Lagoa da Conceição, Florianópolis, SC, 88062-120</p> <br />

                </div>
             
                    <a href="https://outbox.inf.br" target="_blank" rel="noreferrer"><img src="https://magiafloripa.com.br/assets/outbox.png" alt="Desenvolvido Por OutBox Tecnologia" title="Desenvolvido Por OutBox Tecnologia" /></a>
              

            </div>


        </div>

    )


}

export default Footer;






