import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Footer from '../components/footer';

import { db } from "../components/firebase";
import { doc, getDoc, addDoc, collection, query, where, getDocs, orderBy, limit, serverTimestamp } from "firebase/firestore";
import axios from 'axios';
import { MdSearch } from 'react-icons/md'

import Menu from '../components/menu';
import Topo from '../components/topo';
import { Button, Spinner, Row, Col, Form, Modal } from 'react-bootstrap';

import { msgAviso } from '../components/sweet';


function PaginaIdentificacao() {

    let params = useParams();

    const [smsKey, setSmsKey] = useState('IQRMWWHGNKAFWFKG5N7QG3YDDVKCZ6WSEGQ17ZFZJ938W59WSMH4YTDVLBZV2E8110H4FZFOWBYD97A6H1HYZAOZSPXAEN0KAKCC5OLPXFH80O22DHU1PX1TQEETNG97')


    const [vagaSelecionada, setVagaSelecionada] = useState("")
    const [cpf, setCpf] = useState("")
    const [cpfInvalido, setCpfInvalido] = useState(false)
    const [exibeFormulario, setExibeFormulario] = useState(false)
    const [loadingCep, setLoadingCep] = useState(false)
    const [loadingEnvio, setLoadingEnvio] = useState(false)
    const [loadingCandidato, setLoadingCandidato] = useState(false)
    const [loadingContinuar, setLoadingContinuar] = useState(false)
    const [exibeFormSMS, setExibeFormSMS] = useState(false)
    const [exibeFormToken, setExibeFormToken] = useState(false)

    const [smsEnviado, setSmsEnviado] = useState("")
    const [smsDigitado, setSmsDigitado] = useState("")

    const [celularCandidato, setCelularCandidato] = useState("")
    const [numeroCelularCandidato, setNumeroCelularCandidato] = useState("")

    const [candidato, setCandidato] = useState([])

    const [experiencia, setExperiencia] = useState(false)

    const [dadosFormulario, setDadosFormulario] = useState(
        {
            nome: "",
            vaga: "",
            cpf: "",
            email: "",
            data_nascimento: "",
            sexo: "",
            naturalidade: "",
            endereco: "",
            numero: "",
            complemento: "",
            bairro: "",
            cep: "",
            telefone: "",
            celular: "",
            cidade: "",
            uf: "",
            experiencia: "",
            trabalhouConosco: "",
            deficiencia: "",
            escolaridade: "",
            cursos: "",
            empresa1: "",
            cargo1: "",
            tempo1: "",
            desligamento1: "",
            salario1: "",
            empresa2: "",
            cargo2: "",
            tempo2: "",
            desligamento2: "",
            salario2: "",
            empresa3: "",
            cargo3: "",
            tempo3: "",
            desligamento3: "",
            salario3: "",
        }
    );

    useEffect(() => {
        CarregaVagaSelecionada();
    }, [])

    useEffect(() => {
        if (dadosFormulario.experiencia === 'Sim') {
            setExperiencia(true)
        } else {
            setExperiencia(false)
        }
    }, [dadosFormulario.experiencia])

    function formataCelular(v) {
        v = v.replace(/\D/g, '')
            .replace(/(\d{2})(\d)/, '($1) $2')
            .replace(/(\d{5})(\d)/, '$1-$2')
            .replace(/(-\d{4})\d+?$/, '$1')
        return v;
    }

    function formataFixo(v) {
        v = v.replace(/\D/g, '')
            .replace(/(\d{2})(\d)/, '($1) $2')
            .replace(/(\d{4})(\d)/, '$1-$2')
            .replace(/(-\d{4})\d+?$/, '$1')
        return v;
    }

    function TestaCPF(strCPF) {
        var Soma;
        var Resto;
        var i;
        Soma = 0;
        if (strCPF === "00000000000") return false;

        for (i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
        Resto = (Soma * 10) % 11;

        if ((Resto === 10) || (Resto === 11)) Resto = 0;
        if (Resto !== parseInt(strCPF.substring(9, 10))) return false;

        Soma = 0;
        for (i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
        Resto = (Soma * 10) % 11;

        if ((Resto === 10) || (Resto === 11)) Resto = 0;
        if (Resto !== parseInt(strCPF.substring(10, 11))) return false;
        return true;
    }

    function formatarCEP(str) {
        var re = /^([\d]{2})\.?([\d]{3})\-?([\d]{3})/;

        if (re.test(str)) {
            return str.replace(re, "$1.$2-$3");
        }
    }

    async function AutenticaSMS() {

        const url = "https://api.smstoken.com.br/token/v1/verify"
        console.log('Requisição Enviada: ' + url);

        let body = {
            "key": smsKey,
            "number": numeroCelularCandidato,
            "template": "<#> Seu código de verificação é: {999-999}",
            "expire": 240
        }

        console.log('Dados Enviados: ' + JSON.stringify(body))

        try {
            let request = new XMLHttpRequest()
            request.timeout = 4000
            request.open("GET", url, true)
            request.setRequestHeader("Content-Type", "application/json")
            request.setRequestHeader("Accept", "*/*")
            request.setRequestHeader("Access-Control-Allow-Origin", "true")
            request.setRequestHeader("allowedOrigins", "*")

            request.send(JSON.stringify(body))

            request.ontimeout = function () {
                msgAviso('TimeOut', 'Erro no Envio do SMS', 'warning', 'ok')
            }

            request.onerror = function (e) {
                msgAviso('Falha na Requisição', e, 'warning', 'ok')
            }

            request.onload = function () {

                let respostaJson = JSON.parse(this.response)
                console.log(respostaJson)
                setExibeFormToken(true)

            }
        } catch (e) {
            msgAviso('Falha no Processo', e, 'warning', 'ok')
        }
    }

    function buscaEndereco(cepDigitado) {
        console.log('Iniando Busca por CEP ' + cepDigitado)
        var cep = cepDigitado.replace(/\D/g, '')
        console.log('CEP Recebido: ' + cep)
        console.log('Telefone Formatado: ' + formataCelular(dadosFormulario.celular))
        if (cep !== "") {
            var validaCep = /^[0-9]{8}$/;
            if (validaCep.test(cep)) {
                setLoadingCep(true)
                console.log('Buscando CEP: ' + `https://viacep.com.br/ws/${cep}/json/`)

                fetch(`https://viacep.com.br/ws/${cep}/json/`)
                    .then((res) => res.json())
                    .then((data) => {
                        setLoadingCep(false)
                        console.log('achou ' + data.logradouro)
                        setDadosFormulario({
                            ...dadosFormulario,
                            endereco: data.logradouro,
                            bairro: data.bairro,
                            cidade: data.localidade,
                            uf: data.uf,

                        })

                        // this.state.uf = data.uf;
                        // this.state.uf = data.uf;
                        // this.state.cidade = data.localidade;
                        // this.state.uf = data.uf;
                        // this.state.bairro = data.bairro;
                        // this.state.logradouro = data.logradouro;
                    });
            }
        }
    }

    async function CarregaVagaSelecionada() {

        const docRef = doc(db, "vagas", `${params.id}`)
        console.log('Buscando Documento: ' + `${params.id}`)
        const docSnap = await getDoc(docRef)
        if (docSnap.exists()) {
            setVagaSelecionada(docSnap.data())
        } else {
            console.log('Vish')
        }
    };

    async function LocalizaCandidato() {
        setLoadingCandidato(true)
        console.log('Buscando Candidato...' + cpfMask(cpf))
        const consulta = query(collection(db, "candidatos"), where("cpf", "==", cpfMask(cpf)), orderBy("timestamp", "desc"), limit(1));
        const getDados = await getDocs(consulta)
        setCandidato([])
        let numcelular = ""
        getDados.forEach((doc) => {
            setCandidato(item => item.concat({ ...doc.data(), id: doc.id }))
            console.log(doc.data())
            setLoadingCandidato(false)
            const celular = doc.data().celular
            setCelularCandidato(celular)
            numcelular = "55" + celular.replace(/[^\d]/g, "")
            setNumeroCelularCandidato(numcelular)
        })
        setLoadingContinuar(false)
        // if (!numcelular) {
        setExibeFormulario(true)
        // setExibeFormSMS(false)
        // } else {
        // setExibeFormulario(false)
        // setExibeFormSMS(true)
        // }
        //AJUSTAR TOKEN VIA SMS
    }

    async function CadastraCandidato() {
        try {

            if (dadosFormulario.cpf !== '' && dadosFormulario.email !== '') {

                setLoadingEnvio(true)

                console.log(dadosFormulario)

                var data = new Date();
                var dataAtual = String(data.getDate()).padStart(2, '0') + '/' + String(data.getMonth() + 1).padStart(2, '0') + '/' + data.getFullYear();

                var dataNascimento = new Date(dadosFormulario.data_nascimento);
                var format_dataVaga = String(dataNascimento.getDate() + 1).padStart(2, '0') + '/' + String(dataNascimento.getMonth() + 1).padStart(2, '0') + '/' + dataNascimento.getFullYear();

                const docRef = await addDoc(collection(db, "candidatos"), {
                    nome: dadosFormulario.nome,
                    cpf: dadosFormulario.cpf,
                    vaga: vagaSelecionada?.nome,
                    email: dadosFormulario.email,
                    data_nascimento: format_dataVaga,
                    sexo: dadosFormulario.sexo,
                    naturalidade: dadosFormulario.naturalidade,
                    endereco: dadosFormulario.endereco,
                    numero: dadosFormulario.numero,
                    complemento: dadosFormulario.complemento,
                    bairro: dadosFormulario.bairro,
                    cep: dadosFormulario.cep,
                    telefone: dadosFormulario.telefone,
                    celular: dadosFormulario.celular,
                    cidade: dadosFormulario.cidade,
                    uf: dadosFormulario.uf,
                    experiencia: dadosFormulario.experiencia,
                    trabalhouConosco: dadosFormulario.trabalhouConosco,
                    deficiencia: dadosFormulario.deficiencia,
                    escolaridade: dadosFormulario.escolaridade,
                    cursos: dadosFormulario.cursos,
                    empresa1: dadosFormulario.empresa1,
                    cargo1: dadosFormulario.cargo1,
                    tempo1: dadosFormulario.tempo1,
                    desligamento1: dadosFormulario.desligamento1,
                    salario1: dadosFormulario.salario1,
                    empresa2: dadosFormulario.empresa2,
                    cargo2: dadosFormulario.cargo2,
                    tempo2: dadosFormulario.tempo2,
                    desligamento2: dadosFormulario.desligamento2,
                    salario2: dadosFormulario.salario2,
                    empresa3: dadosFormulario.empresa3,
                    cargo3: dadosFormulario.cargo3,
                    tempo3: dadosFormulario.tempo3,
                    desligamento3: dadosFormulario.desligamento3,
                    salario3: dadosFormulario.salario3,
                    datacad: dataAtual,
                    timestamp: serverTimestamp(),
                    rh_entrevistador: "",
                    rh_observacoes: "",
                    rh_cidade_nasceu: "",
                    rh_mora_atualmente: "",
                    rh_quem_mora: "",
                    rh_tipo_moradia: "",
                    rh_tempo_cidade: "",
                    rh_motivo_mudanca: "",
                    rh_grau_escolaridade: "",
                    rh_estado_civil: "",
                    rh_conjuge_trabalha: "",
                    rh_filhos: "",
                    rh_quem_cuida_filhos: "",
                    rh_conhece_parente_funcionario: "",
                    rh_ultimo_emprego: "",
                    rh_porque_saiu_ultimo_emprego: "",
                    rh_disponibilidade_horario: "",
                    rh_trabalhou_supermercado: "",
                    rh_funcao_se_identifica: "",
                    rh_status_avaliacao: "",
                    rh_status_motivo: "",
                });

                console.log("Document written with ID: ", docRef.id);
                setExibeFormulario(false)
                setDadosFormulario({
                    nome: "",
                    cpf: "",
                    email: "",
                    data_nascimento: "",
                    sexo: "",
                    naturalidade: "",
                    endereco: "",
                    numero: "",
                    complemento: "",
                    bairro: "",
                    cep: "",
                    telefone: "",
                    celular: "",
                    cidade: "",
                    uf: "",
                    experiencia: "",
                    trabalhouConosco: "",
                    deficiencia: "",
                    escolaridade: "",
                    cursos: "",
                    empresa1: "",
                    cargo1: "",
                    tempo1: "",
                    desligamento1: "",
                    salario1: "",
                    empresa2: "",
                    cargo2: "",
                    tempo2: "",
                    desligamento2: "",
                    salario2: "",
                    empresa3: "",
                    cargo3: "",
                    tempo3: "",
                    desligamento3: "",
                    salario3: "",
                })
                setExperiencia(false)
                setCpf("")
                msgAviso('Dados Enviados com Sucesso!', 'entraremos em contato em breve!', 'success', 'ok');
                setLoadingEnvio(false)

            } else {
                setLoadingEnvio(false)
                msgAviso('Atenção', 'Preencha os Campos', 'warning', 'ok')
            }

        } catch (e) {
            setLoadingEnvio(false)
            console.error("Error adding document: ", e);
            msgAviso('Falha no Processo', e, 'error', 'ok')
        }
    }

    const cpfMask = value => {
        return value
            .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
            .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d{1,2})/, '$1-$2')
            .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
    }

    return (

        <div className="App">

            <Topo />

            <Menu />

            <div className='conteudo'>
                <br />
                <br />


                <div className='linha-sem-espaco'>
                    <div className='box-1c'>


                        <h3>✓ IDENTIFICAÇÃO</h3>
                        <hr />
                        <p>Você está se candidatando a vaga de <strong>{vagaSelecionada?.nome}</strong></p>
                        <br />

                        <Col>
                            <Row>
                                <Col sm={4}>
                                    <Form>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>Informe seu CPF</Form.Label>
                                            <Form.Control type="text" placeholder="CPF" autoComplete="new-password" onKeyDown={(e) => {

                                                if (e.key == 'Enter') {
                                                    e.preventDefault();
                                                    setLoadingContinuar(true)
                                                    if (TestaCPF(cpf.replace(/\D/g, ''))) {
                                                        setCpfInvalido(false)
                                                        setDadosFormulario({
                                                            ...dadosFormulario,
                                                            experiencia: 'Selecione',
                                                            cpf: cpf
                                                        })
                                                        setExperiencia(false)
                                                        LocalizaCandidato()
                                                    } else {
                                                        setLoadingContinuar(false)
                                                        setCpfInvalido(true)
                                                        setExibeFormulario(false)
                                                    }
                                                }
                                            }} value={cpf} onChange={(e) => setCpf(cpfMask(e.target.value))} />
                                            <Form.Text className="text-muted">
                                                {cpfInvalido &&
                                                    <>
                                                        <strong style={{ color: 'red' }}>CPF Inválido</strong>
                                                        <br />
                                                    </>
                                                }
                                                Seus dados estão protegidos pela LGPD
                                            </Form.Text>
                                        </Form.Group>
                                    </Form>
                                    <Button
                                        variant="success"
                                        onClick={() => {
                                            setLoadingContinuar(true)
                                            if (TestaCPF(cpf.replace(/\D/g, ''))) {
                                                setCpfInvalido(false)
                                                setDadosFormulario({
                                                    ...dadosFormulario,
                                                    experiencia: 'Selecione',
                                                    cpf: cpf
                                                })
                                                setExperiencia(false)
                                                LocalizaCandidato()
                                            } else {
                                                setLoadingContinuar(false)
                                                setCpfInvalido(true)
                                                setExibeFormulario(false)
                                            }
                                        }}
                                    >
                                        {loadingContinuar &&
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                variant="light"
                                            />
                                        }
                                        {!loadingContinuar &&
                                            <>
                                                Continuar
                                            </>
                                        }
                                    </Button>


                                </Col>
                                {/* <Col sm={8}>
                                    {exibeFormSMS &&
                                        <>
                                            <Form>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label>Enviamos um SMS para você, digite abaixo o código recebido</Form.Label>
                                                    <Form.Control type="text" placeholder="CPF" autoComplete="new-password" value={smsDigitado} onChange={(e) => setSmsDigitado(e.target.value)} />
                                                    <Form.Text className="text-muted">
                                                        SMS Enviado para {celularCandidato}
                                                    </Form.Text>
                                                </Form.Group>

                                            </Form>

                                            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                                                <Button
                                                    variant="primary"
                                                    onClick={() => {

                                                    }}
                                                >
                                                    Confirmar
                                                </Button>
                                            </div>
                                        </>
                                    }
                                </Col> */}

                                <Modal //Modal Mensagem SMS
                                    show={exibeFormSMS}
                                >
                                    <Modal.Header>
                                        <Modal.Title>Seja Bem-Vindo de Volta!</Modal.Title>
                                    </Modal.Header>

                                    <Modal.Body>
                                        <p>
                                            Identificamos que você já se candidatou a alguma vaga em nosso site
                                        </p>
                                        <p>
                                            Para facilitar o processo, podemos reaproveitar os dados que você havia enviado anteriormente
                                        </p>
                                        <p>
                                            Para isso precisamos confirmar sua identidade, enviando um SMS de confirmação para você, tudo bem?
                                        </p>
                                        <strong>
                                            Podemos enviar o SMS para o número {celularCandidato} ?
                                        </strong>
                                    </Modal.Body>

                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={() => {
                                            setExibeFormSMS(false)
                                            setExibeFormulario(true)
                                        }}>Não quero, vou preencher do zero</Button>
                                        <Button variant="success" onClick={() => {
                                            setExibeFormSMS(false)
                                            AutenticaSMS()
                                        }}>Quero continuar</Button>
                                    </Modal.Footer>
                                </Modal>

                                <Modal //Modal Valida SMS
                                    show={exibeFormToken}
                                >
                                    <Modal.Header>
                                        <Modal.Title>Validação de Identidade</Modal.Title>
                                    </Modal.Header>

                                    <Modal.Body>
                                        <Form>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Informe abaixo o código recebido via SMS</Form.Label>
                                                <Form.Control type="text" placeholder="Informe apenas os números" autoComplete="new-password" value={smsDigitado} onChange={(e) => setSmsDigitado(e.target.value)} />
                                                <Form.Text className="text-muted">
                                                    SMS Enviado para {celularCandidato}
                                                </Form.Text>
                                            </Form.Group>
                                        </Form>
                                    </Modal.Body>

                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={() => {
                                            setExibeFormToken(false)
                                        }}>Cancelar</Button>
                                        <Button variant="primary" >Confirmar</Button>
                                    </Modal.Footer>
                                </Modal>
                            </Row>
                            {exibeFormulario &&
                                <>
                                    <br />
                                    <Form>
                                        <Row>
                                            <hr />
                                            <h3>Dados Pessoais</h3>
                                            <hr />
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Label>Nome Completo</Form.Label>
                                                <Form.Control type="text" autoComplete="new-password" value={dadosFormulario.nome} onChange={(e) => setDadosFormulario({ ...dadosFormulario, nome: e.target.value })} />
                                            </Col>
                                            <Col>
                                                <Form.Label>E-mail</Form.Label>
                                                <Form.Control type="email" autoComplete="new-password" value={dadosFormulario.email} onChange={(e) => setDadosFormulario({ ...dadosFormulario, email: e.target.value })} />
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col>
                                                <Form.Label>Data de Nascimento</Form.Label>
                                                <Form.Control type="date" autoComplete="new-password" value={dadosFormulario.data_nascimento} onChange={(e) => setDadosFormulario({ ...dadosFormulario, data_nascimento: e.target.value })} />
                                            </Col>
                                            <Col>
                                                <Form.Label>Sexo</Form.Label>
                                                <Form.Select aria-label="Default select example" value={dadosFormulario.sexo} onChange={(e) => { setDadosFormulario({ ...dadosFormulario, sexo: e.target.value }) }}>
                                                    <option>Selecione</option>
                                                    <option value={'Masculino'}>Masculino</option>
                                                    <option value={'Feminino'}>Feminino</option>
                                                    <option value={'Prefico Não Informar'}>Prefico Não Informar</option>
                                                </Form.Select>
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col>
                                                <Form.Label>Naturalidade</Form.Label>
                                                <Form.Control type="text" autoComplete="new-password" value={dadosFormulario.naturalidade} onChange={(e) => setDadosFormulario({ ...dadosFormulario, naturalidade: e.target.value })} />
                                            </Col>
                                            <Col>

                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>

                                            <hr />
                                            <h3>Endereço</h3>
                                            <hr />

                                        </Row>
                                        <Row>
                                            <Col sm={4}>
                                                <Row>
                                                    <Col sm={10}>
                                                        <Form.Label>CEP</Form.Label>
                                                        <Form.Control type="text" autoComplete="new-password" value={dadosFormulario.cep} onChange={(e) => setDadosFormulario({ ...dadosFormulario, cep: formatarCEP(e.target.value) })} />
                                                    </Col>
                                                    <Col sm={2} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                                                        <Button
                                                            variant="success"
                                                            onClick={() => {
                                                                buscaEndereco(dadosFormulario.cep)
                                                            }}
                                                        >
                                                            {loadingCep &&
                                                                <Spinner
                                                                    as="span"
                                                                    animation="border"
                                                                    size="sm"
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                    variant="light"
                                                                />
                                                            }
                                                            {!loadingCep &&
                                                                <MdSearch size="16" color="#fff" title="Buscar Endereço pelo CEP" />
                                                            }

                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col sm={8}>
                                                <Row>
                                                    <Col sm={9}>
                                                        <Form.Label>Endereço</Form.Label>
                                                        <Form.Control type="text" autoComplete="new-password" value={dadosFormulario.endereco} onChange={(e) => setDadosFormulario({ ...dadosFormulario, endereco: e.target.value })} />
                                                    </Col>
                                                    <Col sm={3}>
                                                        <Form.Label>Número</Form.Label>
                                                        <Form.Control type="text" autoComplete="new-password" value={dadosFormulario.numero} onChange={(e) => setDadosFormulario({ ...dadosFormulario, numero: e.target.value })} />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col>
                                                <Form.Label>Complemento</Form.Label>
                                                <Form.Control type="text" autoComplete="new-password" value={dadosFormulario.complemento} onChange={(e) => setDadosFormulario({ ...dadosFormulario, complemento: e.target.value })} />
                                            </Col>
                                            <Col>
                                                <Form.Label>Bairro</Form.Label>
                                                <Form.Control type="text" autoComplete="new-password" value={dadosFormulario.bairro} onChange={(e) => setDadosFormulario({ ...dadosFormulario, bairro: e.target.value })} />
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col>
                                                <Form.Label>Cidade</Form.Label>
                                                <Form.Control type="text" autoComplete="new-password" value={dadosFormulario.cidade} onChange={(e) => setDadosFormulario({ ...dadosFormulario, cidade: e.target.value })} />
                                            </Col>
                                            <Col>
                                                <Form.Label>Estado</Form.Label>
                                                <Form.Control type="text" autoComplete="new-password" value={dadosFormulario.uf} onChange={(e) => setDadosFormulario({ ...dadosFormulario, uf: e.target.value })} />
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col>
                                                <Form.Label>Telefone Fixo</Form.Label>
                                                <Form.Control type="tel" autoComplete="new-password" value={dadosFormulario.telefone} placeholder={'Exemplo: (48) 3333-4444'} onChange={(e) => setDadosFormulario({ ...dadosFormulario, telefone: formataFixo(e.target.value) })} />
                                            </Col>
                                            <Col>
                                                <Form.Label>Telefone Celular</Form.Label>
                                                <Form.Control type="tel" autoComplete="new-password" value={dadosFormulario.celular} placeholder={'Exemplo: (48) 99999-9999'} onChange={(e) => setDadosFormulario({ ...dadosFormulario, celular: formataCelular(e.target.value) })} />
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>

                                            <hr />
                                            <h3>Escolaridade</h3>
                                            <hr />

                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Label>Selecione Sua Escolaridade</Form.Label>
                                                <Form.Select aria-label="Default select example" value={dadosFormulario.escolaridade} onChange={(e) => { setDadosFormulario({ ...dadosFormulario, escolaridade: e.target.value }) }}>
                                                    <option>Selecione</option>
                                                    <option value={'Ensino Fundamental Incompleto'}>Ensino Fundamental Incompleto</option>
                                                    <option value={'Ensino Fundamental Completo'}>Ensino Fundamental Completo</option>
                                                    <option value={'Ensino Médio Incompleto'}>Ensino Médio Incompleto</option>
                                                    <option value={'Ensino Médio Completo'}>Ensino Médio Completo</option>
                                                    <option value={'Ensino Superior Incompleto'}>Ensino Superior Incompleto</option>
                                                    <option value={'Ensino Superior Completo'}>Ensino Superior Completo</option>

                                                </Form.Select>
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col>
                                                <Form.Label>Cursos e Especializações</Form.Label>
                                                <Form.Control as="textarea" style={{ whiteSpace: 'pre-line' }} rows={6} autoComplete="new-password" value={dadosFormulario.cursos} placeholder={'Coloque o máximo de informação possível'} onChange={(e) => setDadosFormulario({ ...dadosFormulario, cursos: e.target.value })} />
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>

                                            <hr />
                                            <h3>Dados Adicionais</h3>
                                            <hr />

                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Label>Já Trabalhou Conosco Antes?</Form.Label>
                                                <Form.Select aria-label="Default select example" value={dadosFormulario.trabalhouConosco} onChange={(e) => { setDadosFormulario({ ...dadosFormulario, trabalhouConosco: e.target.value }) }}>
                                                    <option>Selecione</option>
                                                    <option value={'Sim'}>Sim</option>
                                                    <option value={'Não'}>Não</option>
                                                </Form.Select>
                                            </Col>
                                            <Col>
                                                <Form.Label>Possui Alguma Deficiência?</Form.Label>
                                                <Form.Select aria-label="Default select example" value={dadosFormulario.deficiencia} onChange={(e) => { setDadosFormulario({ ...dadosFormulario, deficiencia: e.target.value }) }}>
                                                    <option>Selecione</option>
                                                    <option value={'Sim'}>Sim</option>
                                                    <option value={'Não'}>Não</option>
                                                </Form.Select>
                                            </Col>
                                            <Col>
                                                <Form.Label>Possui Experiência?</Form.Label>
                                                <Form.Select aria-label="Default select example" value={dadosFormulario.experiencia} onChange={(e) => { setDadosFormulario({ ...dadosFormulario, experiencia: e.target.value }) }}>
                                                    <option>Selecione</option>
                                                    <option value={'Sim'}>Sim</option>
                                                    <option value={'Não'}>Não</option>
                                                </Form.Select>
                                            </Col>
                                        </Row>
                                        {experiencia &&
                                            <>
                                                <br />
                                                <Row>

                                                    <hr />
                                                    <h3>Experiências Profissionais</h3>
                                                    <hr />

                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Form.Label>Empresa</Form.Label>
                                                        <Form.Control type="text" autoComplete="new-password" value={dadosFormulario.empresa1} onChange={(e) => setDadosFormulario({ ...dadosFormulario, empresa1: e.target.value })} />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label>Cargo</Form.Label>
                                                        <Form.Control type="text" autoComplete="new-password" value={dadosFormulario.cargo1} onChange={(e) => setDadosFormulario({ ...dadosFormulario, cargo1: e.target.value })} />
                                                    </Col>
                                                </Row>
                                                <br />
                                                <Row>
                                                    <Col>
                                                        <Form.Label>Tempo na Empresa</Form.Label>
                                                        <Form.Control type="text" autoComplete="new-password" value={dadosFormulario.tempo1} onChange={(e) => setDadosFormulario({ ...dadosFormulario, tempo1: e.target.value })} />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label>Motivo Desligamento</Form.Label>
                                                        <Form.Control type="text" autoComplete="new-password" value={dadosFormulario.desligamento1} onChange={(e) => setDadosFormulario({ ...dadosFormulario, desligamento1: e.target.value })} />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label>Salário</Form.Label>
                                                        <Form.Control type="text" autoComplete="new-password" value={dadosFormulario.salario1} onChange={(e) => setDadosFormulario({ ...dadosFormulario, salario1: e.target.value })} />
                                                    </Col>
                                                </Row>
                                                <br />
                                                <hr />
                                                <br />
                                                <Row>
                                                    <Col>
                                                        <Form.Label>Empresa</Form.Label>
                                                        <Form.Control type="text" autoComplete="new-password" value={dadosFormulario.empresa2} onChange={(e) => setDadosFormulario({ ...dadosFormulario, empresa2: e.target.value })} />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label>Cargo</Form.Label>
                                                        <Form.Control type="text" autoComplete="new-password" value={dadosFormulario.cargo2} onChange={(e) => setDadosFormulario({ ...dadosFormulario, cargo2: e.target.value })} />
                                                    </Col>
                                                </Row>
                                                <br />
                                                <Row>
                                                    <Col>
                                                        <Form.Label>Tempo na Empresa</Form.Label>
                                                        <Form.Control type="text" autoComplete="new-password" value={dadosFormulario.tempo2} onChange={(e) => setDadosFormulario({ ...dadosFormulario, tempo2: e.target.value })} />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label>Motivo Desligamento</Form.Label>
                                                        <Form.Control type="text" autoComplete="new-password" value={dadosFormulario.desligamento2} onChange={(e) => setDadosFormulario({ ...dadosFormulario, desligamento2: e.target.value })} />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label>Salário</Form.Label>
                                                        <Form.Control type="text" autoComplete="new-password" value={dadosFormulario.salario2} onChange={(e) => setDadosFormulario({ ...dadosFormulario, salario2: e.target.value })} />
                                                    </Col>
                                                </Row>
                                                <br />
                                                <hr />
                                                <br />
                                                <Row>
                                                    <Col>
                                                        <Form.Label>Empresa</Form.Label>
                                                        <Form.Control type="text" autoComplete="new-password" value={dadosFormulario.empresa3} onChange={(e) => setDadosFormulario({ ...dadosFormulario, empresa3: e.target.value })} />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label>Cargo</Form.Label>
                                                        <Form.Control type="text" autoComplete="new-password" value={dadosFormulario.cargo3} onChange={(e) => setDadosFormulario({ ...dadosFormulario, cargo3: e.target.value })} />
                                                    </Col>
                                                </Row>
                                                <br />
                                                <Row>
                                                    <Col>
                                                        <Form.Label>Tempo na Empresa</Form.Label>
                                                        <Form.Control type="text" autoComplete="new-password" value={dadosFormulario.tempo3} onChange={(e) => setDadosFormulario({ ...dadosFormulario, tempo3: e.target.value })} />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label>Motivo Desligamento</Form.Label>
                                                        <Form.Control type="text" autoComplete="new-password" value={dadosFormulario.desligamento3} onChange={(e) => setDadosFormulario({ ...dadosFormulario, desligamento3: e.target.value })} />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label>Salário</Form.Label>
                                                        <Form.Control type="text" autoComplete="new-password" value={dadosFormulario.salario3} onChange={(e) => setDadosFormulario({ ...dadosFormulario, salario3: e.target.value })} />
                                                    </Col>
                                                </Row>
                                            </>
                                        }
                                        <br />
                                        <br />
                                        <br />
                                        <Row>
                                            <Col
                                                style={{ display: 'flex', justifyContent: 'center', alignItens: 'center', flex: '1' }}
                                            >
                                                {loadingEnvio &&
                                                    <Button
                                                        variant="success"
                                                        size="lg"
                                                        onClick={() => {
                                                            CadastraCandidato()
                                                        }}
                                                    >
                                                        <Spinner
                                                            as="span"
                                                            animation="border"
                                                            size="lg"
                                                            role="status"
                                                            aria-hidden="true"
                                                            variant="light"
                                                        />
                                                    </Button>
                                                }
                                                {!loadingEnvio &&
                                                    <Button
                                                        variant="success"
                                                        size="lg"
                                                        onClick={() => {
                                                            CadastraCandidato()
                                                        }}
                                                    >
                                                        Enviar Meus Dados
                                                    </Button>
                                                }

                                            </Col>
                                        </Row>
                                    </Form>
                                </>
                            }
                        </Col>

                        <br /><br /><br /><br />

                    </div>

                </div>
            </div>
            <Footer />
        </div >


    );
}

export default PaginaIdentificacao;
