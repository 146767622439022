import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

import { Button, Container, Modal, Spinner, Form, ProgressBar, Badge } from 'react-bootstrap'
import DataTable from 'react-data-table-component';

import { collection, getDocs, addDoc, doc, deleteDoc, updateDoc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage"

import { db, storage } from "../../components/firebase";

import { msgAviso } from "../../components/sweet";

import Navegacao from "../../components/navbar";


function Banners() {

    const navigate = useNavigate();

    const [progress, setProgress] = useState(0);

    const [slides, setSlides] = useState([]);

    const [idClicado, setIdClicado] = useState('');
    const [nomeClicado, setNomeClicado] = useState('');
    const [linkClicado, setLinkClicado] = useState('');
    const [arquivoImagemClicado, setArquivoImagemClicado] = useState('');
    const [nomeArquivoClicado, setNomeArquivoClicado] = useState('');


    const [imgNome, setImgNome] = useState('')
    const [imgLink, setImgLink] = useState('')
    const [imgArquivo, setImgArquivo] = useState(null)

    const [loadingTable, setLoadingTable] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingExcluir, setLoadingExcluir] = useState(false);

    const [modalNovoSlide, setModalNovoSlide] = useState(false);
    const [modalEditaSlide, setModalEditaSlide] = useState(false);

    async function CarregaSlides() {

        setLoadingTable(true)
        const getDados = await getDocs(collection(db, "slides"))
        setSlides([])
        getDados.forEach((doc) => {
            setSlides(slides => slides.concat({ ...doc.data(), id: doc.id }))
            console.log(doc.data())
            setLoadingTable(false)
        }
        )
        setLoadingTable(false)
    };

    async function cadastrarSlide() {
        try {
            if (imgNome !== '' && imgLink !== '' && imgArquivo !== '') {
                setLoading(true)

                uploadFiles(imgArquivo)
            } else {
                msgAviso('Atenção', 'Informe Todos os Campos!', 'warning', 'ok');
            }
        } catch (e) {
            console.error("Error adding document: ", e);
        }
    }

    async function excluirImagem(fileUrl) {
        setLoadingExcluir(true)
        const arquivo = ref(storage, `files/${fileUrl}`)
        deleteObject(arquivo)
            .then(() => {
                console.log('Deletou')
                DeletaSlide()
            }).catch((error) => {
                DeletaSlide()
                console.log('Deu Ruim')
                console.log(error)
                setLoadingExcluir(false)
            })

    }

    async function AtualizaCadastro() {
        setModalEditaSlide(false)
        const userDoc = doc(db, "slides", idClicado)
        const newFields = { nome: nomeClicado, link: linkClicado };
        await updateDoc(userDoc, newFields);
        CarregaSlides()
    }

    async function DeletaSlide() {
        setModalEditaSlide(false)
        setLoadingExcluir(false)
        const userDoc = doc(db, "slides", idClicado)
        await deleteDoc(userDoc)
        CarregaSlides()
    }

    useEffect(() => {
        const logado = localStorage.getItem("logado");
        if (logado !== 'S') {
            navigate("/intranet")
        } else {
            CarregaSlides()
        }
    }, [navigate])

    const colunasIndice = [
        {
            name: 'Descrição',
            selector: row => row.nome,
            sortable: true,
        },
        {
            name: 'Nome Arquivo',
            selector: row => row.nomeArquivo,
            sortable: true,
        },
        {
            name: 'Link ao Clicar',
            selector: row => row.link,
            sortable: true,
        },
        {
            name: 'Data Cadastro',
            selector: row => row.datacad,
            sortable: true,
        },
        {
            name: 'Arquivo Imagem',
            selector: row => row.imagem,
            omit: true,
        },
    ];


    function ExibeModalNovoSlide() {
        setImgNome('')
        setImgLink('')
        setImgArquivo('')
        setModalNovoSlide(true)
    }

    function ExibeModalEditaSlide(idClicado, nomeClicado, linkClicado, arquivoClicado, imagemClicado) {
        setIdClicado(idClicado)
        setNomeClicado(nomeClicado)
        setLinkClicado(linkClicado)
        setNomeArquivoClicado(arquivoClicado)
        setArquivoImagemClicado(imagemClicado)
        setModalEditaSlide(true)
    }

    const uploadFiles = (file) => {
        //
        if (!file) return;
        const sotrageRef = ref(storage, `files/${file.name}`);
        const uploadTask = uploadBytesResumable(sotrageRef, file);

        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const prog = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
                setProgress(prog);
            },
            (error) => console.log(error),
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    console.log("File available at", downloadURL);

                    async function cadastra() {

                        var data = new Date();
                        var dataAtual = String(data.getDate()).padStart(2, '0') + '/' + String(data.getMonth() + 1).padStart(2, '0') + '/' + data.getFullYear();

                        const docRef = await addDoc(collection(db, "slides"), {
                            nome: imgNome,
                            link: imgLink,
                            imagem: downloadURL,
                            nomeArquivo: file.name,
                            datacad: dataAtual,
                        });

                        console.log("Document written with ID: ", docRef.id);
                        setLoading(false)
                        setImgNome('')
                        setImgLink('')
                        setModalNovoSlide(false)
                        msgAviso('Parabéns', 'Slide Cadastrado com Sucesso!', 'success', 'ok');
                        CarregaSlides()


                    }

                    cadastra()


                });
            }
        );
    };

    return (

        <>
            <Navegacao />

            <Container>
                <br />
                <h1>Slides</h1>

                <br />
                <h3>Slides Cadastrados</h3>
                <div className="linha-sem-espaco-direita">
                    <Button variant="success" onClick={() => { ExibeModalNovoSlide() }} >+</Button>
                </div>


                {loadingTable &&

                    <div className="centraliza">

                        <Spinner
                            as="span"
                            animation="border"
                            size="lg"
                            role="status"
                            aria-hidden="true"
                            variant="primary"
                        />
                    </div>


                }

                {!loadingTable &&
                    <DataTable
                        columns={colunasIndice}
                        data={slides}
                        striped={true}
                        highlightOnHover={true}
                        responsive={true}
                        pointerOnHover={true}
                        onRowClicked={(row, index) => { ExibeModalEditaSlide(row.id, row.nome, row.link, row.nomeArquivo, row.imagem) }}
                        noDataComponent={"Nenhum Registro Encontrado =/"}
                    />
                }





            </Container>

            <Modal //Modal Edita Cadastro
                show={modalEditaSlide}
                dialogClassName="modal-50"
            >
                <Modal.Header>
                    <Modal.Title>Edição de Cadastro</Modal.Title>

                </Modal.Header>

                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Nome</Form.Label>
                            <Form.Control type="text" placeholder="Nome" value={nomeClicado} onChange={(e) => { setNomeClicado(e.target.value) }} />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Link</Form.Label>
                            <Form.Control type="text" placeholder="Link ao Clicar na Imagem" value={linkClicado} onChange={(e) => { setLinkClicado(e.target.value) }} />
                        </Form.Group>


                    </Form>

                    <div className="centraliza-img-banner">
                        <img src={arquivoImagemClicado} alt={'Preview'} />
                    </div>


                </Modal.Body>

                <Modal.Footer>
                    {!loadingExcluir &&
                        <Button variant="danger" onClick={() => { excluirImagem(nomeArquivoClicado) }}>Excluir</Button>
                    }

                    {loadingExcluir &&
                        <Button variant="danger" >
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                variant="light"
                            />
                        </Button>
                    }



                    {loading &&
                        <Button variant="success">
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                variant="light"
                            />
                        </Button>
                    }
                    {!loading &&
                        <Button variant="success" onClick={() => { (AtualizaCadastro()) }}>Salvar</Button>
                    }
                    <Button variant="secondary" onClick={() => { setModalEditaSlide(false) }}>Fechar</Button>
                </Modal.Footer>
            </Modal>

            <Modal //Modal Novo Slide
                show={modalNovoSlide}
                dialogClassName="modal-50"
            >
                <Modal.Header>
                    <Modal.Title>Novo Cadastro</Modal.Title>

                </Modal.Header>

                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Nome</Form.Label>
                            <Form.Control type="text" placeholder="Nome" value={imgNome} required onChange={(e) => { setImgNome(e.target.value) }} />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Link <a onClick={() => { setImgLink('#') }} className="link-banner">Sem Link</a></Form.Label>
                            <Form.Control type="text" placeholder="Link ao Clicar na Imagem" value={imgLink} required onChange={(e) => { setImgLink(e.target.value) }} />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Imagem</Form.Label>
                            <Form.Control type="file" placeholder="Imagem" accept="image/png, image/jpeg" required onChange={(e) => { setImgArquivo(e.target.files[0]) }} />
                        </Form.Group>
                        <Badge pill bg="info">
                            Dimensões Recomendadas: 1920x480
                        </Badge>


                    </Form>

                    {loading &&

                        <ProgressBar
                            animated={true}
                            now={progress}
                            variant="success"
                            label={`${progress}%`}
                        />


                    }


                </Modal.Body>

                <Modal.Footer>

                    <Button variant="danger" onClick={() => { setModalNovoSlide(false) }}>Cancelar</Button>

                    {loading &&
                        <Button variant="success" >
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                variant="light"
                            />
                        </Button>
                    }
                    {!loading &&
                        <Button variant="success" onClick={() => { (cadastrarSlide()) }}>Salvar</Button>
                    }

                </Modal.Footer>

            </Modal>


        </>
    );
}

export default Banners;
