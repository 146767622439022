import Menu from '../components/menu';
import Topo from '../components/topo';

function Desconhecido() {

    return (

        <div className="App">


            <Topo />

            <Menu />

            <div className="desconhecido">
                <h1>
                    Erro 404 - Não Encontrado
                </h1>
            </div>



        </div>
    );
}

export default Desconhecido;
