import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Footer from '../components/footer';

import DataTable from 'react-data-table-component';

import { db } from "../components/firebase";
import { collection, getDocs, query, where } from "firebase/firestore";



import Menu from '../components/menu';
import Topo from '../components/topo';
import { Button, Modal, Row, Col, Form } from 'react-bootstrap';


function TrabalheConosco() {

    useEffect(() => {
        CarregaVagas()
    }, [])

    const cpfMask = value => {
        return value
            .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
            .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d{1,2})/, '$1-$2')
            .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
    }

    const [loadingTable, setLoadingTable] = useState(false)
    const [vagas, setVagas] = useState(false)

    const [modalDescricaoVaga, setModalDescricaoVaga] = useState(false)
    const [modalCPF, setModalCPF] = useState(false)
    const [descricaoVaga, setDescricaoVaga] = useState("")
    const [cpf, setCpf] = useState("")

    const colunasQuadroVagas = [
        {
            name: 'Nome',
            selector: row => row.nome,
            sortable: true,
        },
        {
            name: 'Inicio',
            selector: row => row.inicio_vaga,
            sortable: true,
            omit: true,
        },
        {
            name: 'Faixa Salárial',
            selector: row => row.faixa_salarial,
            sortable: true,
        },
        {
            name: '',
            button: true,
            cell: (row, index, column, id) => {
                return (
                    <Link
                        to={`/identificacao/${row.id}`}
                        key={row.id}
                    >
                        <Button
                            variant="success"
                            size="sm"
                            onClick={() => {
                                console.log(row.id)
                            }}
                        >Candidate-se</Button>
                    </Link>
                )

            }


            // cell: row => (


        },
        {
            name: 'Status',
            selector: row => row.status,
            omit: true,
        },
        {
            name: 'Data Cadastro',
            selector: row => row.datacad,
            omit: true,
        },
        {
            name: 'Descrição',
            selector: row => row.descricao,
            omit: true,
        },
        {
            name: 'Inicio Publicação',
            selector: row => row.data_inicio,
            omit: true,
        },
        {
            name: 'Fim Publicação',
            selector: row => row.data_fim,
            omit: true,
        },
    ];

    async function CarregaVagas() {

        setLoadingTable(true)
        const consulta = query(collection(db, "vagas"), where("status", "==", "ATIVA"));
        const getDados = await getDocs(consulta)
        setVagas([])
        getDados.forEach((doc) => {
            setVagas(item => item.concat({ ...doc.data(), id: doc.id }))
            console.log(doc.data())
            setLoadingTable(false)
        }
        )
        setLoadingTable(false)
    };

    return (

        <div className="App">

            <Topo />

            <Menu />

            <div className='conteudo'>
                <br />
                <br />


                <div className='linha-sem-espaco'>
                    <div className='box-1c'>
                        {/* <br />
                        <br />
                        <Link to="/rh-meu-cadastro"><h3>✓ MEU CADASTRO</h3></Link>
                        <p>Cadastre-se e seja avisado sempre que uma vaga estiver disponível.
                        </p>
                        <br />
                        <hr />
                        <br /><br /> */}

                        <h3>✓ VAGAS DISPONÍVEIS</h3>
                        <hr />

                        {!loadingTable &&

                            <Col>
                                <Row>
                                    <DataTable
                                        columns={colunasQuadroVagas}
                                        data={vagas}
                                        striped={true}
                                        highlightOnHover={true}
                                        responsive={true}
                                        pointerOnHover={true}
                                        noDataComponent={"Em Breve Novas Vagas Disponíveis"}
                                        onRowClicked={(row, index) => {

                                            setDescricaoVaga(row.descricao)
                                            setModalDescricaoVaga(true)

                                        }}
                                    />
                                </Row>
                            </Col>

                        }

                        <br /><br /><br /><br />

                        <Modal //Modal Descrição da Vaga
                            show={modalDescricaoVaga}
                            onHide={() => {
                                setModalDescricaoVaga(false)
                            }}
                        // backdrop="static"
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Descrição da Vaga</Modal.Title>

                            </Modal.Header>

                            <Modal.Body>
                                <div
                                    style={{ whiteSpace: 'pre-line' }}
                                >
                                    {descricaoVaga}
                                </div>
                            </Modal.Body>

                            {/* <Modal.Footer>
                        <Button variant="success" onClick={() => { (cadastrarNewsletter()) }}>Salvar</Button>
                        </Modal.Footer> */}
                        </Modal>

                        <Modal //Modal CPF
                            show={modalCPF}
                            onHide={() => {
                                setModalCPF(false)
                            }}
                            backdrop="static"
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Informe seu CPF</Modal.Title>

                            </Modal.Header>

                            <Modal.Body>

                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="descricao">
                                            <Row>
                                                <Col sm={8}>
                                                    <Form.Control type="text" autoComplete="new-password" placeholder="" value={cpf} onChange={(e) => setCpf(cpfMask(e.target.value))} />
                                                </Col>

                                            </Row>

                                        </Form.Group>

                                    </Col>
                                </Row>

                            </Modal.Body>

                            <Modal.Footer>
                                <Button variant="success" onClick={() => { alert('OK') }}>Continuar</Button>
                            </Modal.Footer>
                        </Modal>


                    </div>

                </div>
            </div>
            <Footer />
        </div >


    );
}

export default TrabalheConosco;
