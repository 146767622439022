import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

import { Button, Container, Modal, Spinner, Form, Row, Col, Tab, Tabs, ButtonGroup, Dropdown, DropdownButton, Accordion } from 'react-bootstrap'
import DataTable from 'react-data-table-component';

import { TiDelete } from 'react-icons/ti'

import Swal from 'sweetalert2'

import { collection, getDocs, addDoc, doc, deleteDoc, updateDoc, getDoc } from "firebase/firestore";

import { db } from "../../../components/firebase";

import { msgAviso } from "../../../components/sweet";

import Navegacao from "../../../components/navbar";

function Candidatos() {

    const navigate = useNavigate();

    const [loadingTable, setLoadingTable] = useState(false);

    const [loading, setLoading] = useState(false);

    const [modoEdicaoVaga, setModoEdicaoVaga] = useState(false);
    const [idCandidato, setIdCandidato] = useState('');

    const [modalDetVaga, setModalDetVaga] = useState(false);
    const [modalModelos, setModalModelos] = useState(false);

    const [candidatos, setCandidatos] = useState([]);
    const [modelos, setModelos] = useState([]);

    const [menuNov, setMenuNov] = useState(true);
    const [menuArq, setMenuArq] = useState(false);
    const [menuRej, setMenuRej] = useState(false);
    const [menuTod, setMenuTod] = useState(false);

    const [modoEntrevista, setModoEntrevista] = useState(false);

    const [candidatoAtual, setCandidatoAtual] = useState(
        {
            nome: "",
            vaga: "",
            cpf: "",
            email: "",
            data_nascimento: "",
            sexo: "",
            naturalidade: "",
            endereco: "",
            numero: "",
            complemento: "",
            bairro: "",
            cep: "",
            telefone: "",
            celular: "",
            cidade: "",
            uf: "",
            experiencia: "",
            trabalhouConosco: "",
            deficiencia: "",
            escolaridade: "",
            cursos: "",
            empresa1: "",
            cargo1: "",
            tempo1: "",
            desligamento1: "",
            salario1: "",
            empresa2: "",
            cargo2: "",
            tempo2: "",
            desligamento2: "",
            salario2: "",
            empresa3: "",
            cargo3: "",
            tempo3: "",
            desligamento3: "",
            salario3: "",
            rh_entrevistador: "",
            rh_observacoes: "",
            rh_cidade_nasceu: "",
            rh_mora_atualmente: "",
            rh_quem_mora: "",
            rh_tipo_moradia: "",
            rh_tempo_cidade: "",
            rh_motivo_mudanca: "",
            rh_grau_escolaridade: "",
            rh_estado_civil: "",
            rh_conjuge_trabalha: "",
            rh_filhos: "",
            rh_quem_cuida_filhos: "",
            rh_conhece_parente_funcionario: "",
            rh_ultimo_emprego: "",
            rh_porque_saiu_ultimo_emprego: "",
            rh_disponibilidade_horario: "",
            rh_trabalhou_supermercado: "",
            rh_funcao_se_identifica: "",
            rh_status_avaliacao: "",
            rh_status_motivo: "",
        }
    );

    async function CarregaCandidato(identificacao) {

        const docRef = doc(db, "candidatos", identificacao)
        console.log('Buscando Documento: ' + identificacao)
        const docSnap = await getDoc(docRef)
        if (docSnap.exists()) {
            setCandidatoAtual(docSnap.data())
        } else {
            msgAviso('Falha ao Carregar Registro', 'Tente Novamente Mais Tarde', 'warning', 'ok')
            setModalDetVaga(false)
        }
    };


    async function CarregaCandidatos() {

        // setLoadingTable(true)
        const getDados = await getDocs(collection(db, "candidatos"))
        setCandidatos([])
        getDados.forEach((doc) => {
            setCandidatos(item => item.concat({ ...doc.data(), id: doc.id }))
            console.log(doc.data())
            setLoadingTable(false)
        }
        )
        setLoadingTable(false)
    };


    async function AtualizaCandidato() {

        // setModalDetVaga(false)

        try {
            const userDoc = doc(db, "candidatos", idCandidato)
            const newFields = {
                rh_entrevistador: candidatoAtual.rh_entrevistador,
                rh_observacoes: candidatoAtual.rh_observacoes,
                rh_cidade_nasceu: candidatoAtual.rh_cidade_nasceu,
                rh_mora_atualmente: candidatoAtual.rh_mora_atualmente,
                rh_quem_mora: candidatoAtual.rh_quem_mora,
                rh_tipo_moradia: candidatoAtual.rh_tipo_moradia,
                rh_tempo_cidade: candidatoAtual.rh_tempo_cidade,
                rh_motivo_mudanca: candidatoAtual.rh_motivo_mudanca,
                rh_grau_escolaridade: candidatoAtual.rh_grau_escolaridade,
                rh_estado_civil: candidatoAtual.rh_estado_civil,
                rh_conjuge_trabalha: candidatoAtual.rh_conjuge_trabalha,
                rh_filhos: candidatoAtual.rh_filhos,
                rh_quem_cuida_filhos: candidatoAtual.rh_quem_cuida_filhos,
                rh_conhece_parente_funcionario: candidatoAtual.rh_conhece_parente_funcionario,
                rh_ultimo_emprego: candidatoAtual.rh_ultimo_emprego,
                rh_porque_saiu_ultimo_emprego: candidatoAtual.rh_porque_saiu_ultimo_emprego,
                rh_disponibilidade_horario: candidatoAtual.rh_disponibilidade_horario,
                rh_trabalhou_supermercado: candidatoAtual.rh_trabalhou_supermercado,
                rh_funcao_se_identifica: candidatoAtual.rh_funcao_se_identifica,
                rh_status_avaliacao: candidatoAtual.rh_status_avaliacao,
                rh_status_motivo: candidatoAtual.rh_status_motivo,
            };
            await updateDoc(userDoc, newFields);
            setIdCandidato('')
            CarregaCandidatos()
            msgAviso('Parabéns', 'Registro Salvo com Sucesso', 'success', 'ok')
        } catch (e) {
            msgAviso('Falha ao Salvar Registro', e, 'warning', 'ok')
        }
    }

    function LimpaDadoscandidatoAtual() {
        console.log('Limpando Dados dos Campos')
        candidatoAtual.nome = ''
        candidatoAtual.descricao = ''
        candidatoAtual.inicio_vaga = ''
        candidatoAtual.data_inicio = ''
        candidatoAtual.data_fim = ''
        candidatoAtual.faixa_salarial = ''
        candidatoAtual.status = ''
    }

    function chamarWhatsApp(telefone) {
        const f1 = telefone.replace(/[^0-9]/g, '')
        const formatado = '55' + f1
        const link = 'http://wa.me/' + formatado
        window.open(link, '_blank');
    }

    function abrirGMaps() {
        const enderecoCompleto = candidatoAtual.endereco + ', ' + candidatoAtual.numero + ' - ' + candidatoAtual.bairro + ', ' + candidatoAtual.cidade + '-' + candidatoAtual?.uf
        const enderecoUrl = enderecoCompleto.replace(' ', '+')
        const link = 'https://www.google.com/maps/place/' + enderecoUrl
        window.open(link, '_blank');
    }

    function rotaGMaps() {
        const enderecoCompleto = candidatoAtual.endereco + ', ' + candidatoAtual.numero + ' - ' + candidatoAtual.bairro + ', ' + candidatoAtual.cidade + '-' + candidatoAtual?.uf
        const enderecoUrl = enderecoCompleto.replace(' ', '+')
        const enderecoLoja = "R.+Manoel+Severino+de+Oliveira,+639+-+Lagoa+da+Conceição,+Florianópolis+-+SC,+88062-120"
        const link = 'https://www.google.com/maps/dir/' + enderecoUrl + '/' + enderecoLoja
        window.open(link, '_blank');
    }

    function FormataStringData(data) {
        var dia = data.split("/")[0];
        var mes = data.split("/")[1];
        var ano = data.split("/")[2];

        return ano + '-' + ("0" + mes).slice(-2) + '-' + ("0" + dia).slice(-2);
    }

    function calcIdade(data) {
        var d = new Date,
            ano_atual = d.getFullYear(),
            mes_atual = d.getMonth() + 1,
            dia_atual = d.getDate(),
            split = data.split('/'),
            novadata = split[1] + "/" + split[0] + "/" + split[2],
            data_americana = new Date(novadata),
            vAno = data_americana.getFullYear(),
            vMes = data_americana.getMonth() + 1,
            vDia = data_americana.getDate(),
            ano_aniversario = +vAno,
            mes_aniversario = +vMes,
            dia_aniversario = +vDia,
            quantos_anos = ano_atual - ano_aniversario;
        if (mes_atual < mes_aniversario || mes_atual == mes_aniversario && dia_atual < dia_aniversario) {
            quantos_anos--;
        }
        return quantos_anos < 0 ? 0 : quantos_anos;
    }

    function IniciaEntrevista() {
        if (candidatoAtual.rh_entrevistador) {
            setModoEntrevista(true)
        } else {
            msgAviso('Atenção', 'Preencha o Campo Entrevistador', 'warning', 'ok')
            setModoEntrevista(false)
        }

    }

    useEffect(() => {
        const logado = localStorage.getItem("logado");
        if (logado !== 'S') {
            navigate("/intranet")
        } else {
            CarregaCandidatos()
        }
    }, [navigate])

    const colunasQuadroVagas = [
        {
            name: 'Nome',
            selector: row => row.nome,
            sortable: true,
        },
        {
            name: 'Data Nascimento',
            selector: row => row.data_nascimento,
            sortable: true,
        },
        {
            name: 'Vaga',
            selector: row => row.vaga,
            sortable: true,
        },
        {
            name: 'Data',
            selector: row => row.datacad,
            sortable: true,
        },

    ];

    return (
        <>
            <Navegacao />
            <Container>
                <br />
                <Row>
                    <h1>Candidatos</h1>
                </Row>

                <Col>
                    <Row>
                        <Col sm={2}>

                            <ButtonGroup vertical style={{ width: '100%', marginTop: '25px', marginLeft: '5px', marginRight: '5px' }}>
                                <Button variant="outline-primary" active={menuNov}>Novos</Button>
                                <Button variant="outline-primary" active={menuArq}>Arquivados</Button>
                                <Button variant="outline-primary" active={menuRej}>Rejeitados</Button>
                                <Button variant="outline-primary" active={menuTod}>Todos</Button>
                            </ButtonGroup>
                        </Col>
                        <Col sm={10}>
                            {loadingTable &&

                                <div className="centraliza">

                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="lg"
                                        role="status"
                                        aria-hidden="true"
                                        variant="primary"
                                    />

                                </div>

                            }

                            {!loadingTable &&
                                <DataTable
                                    columns={colunasQuadroVagas}
                                    data={candidatos}
                                    striped={true}
                                    highlightOnHover={true}
                                    responsive={true}
                                    pointerOnHover={true}
                                    onRowClicked={(row, index) => {

                                        CarregaCandidato(row.id)
                                        setIdCandidato(row.id)
                                        setModalDetVaga(true)

                                    }}
                                    noDataComponent={"Nenhum Registro Encontrado =/"}
                                />
                            }


                        </Col>
                    </Row>
                </Col>

                <Modal //Modal Detalhe Candidato
                    show={modalDetVaga}
                    dialogClassName="modal-75"
                    onHide={() => setModalDetVaga(false)}
                    backdrop="static"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Análise de Candidato</Modal.Title>
                    </Modal.Header>


                    <Modal.Body>
                        <Tabs defaultActiveKey="dados-pessoais" id="tab-candidato" className="mb-3">
                            <Tab eventKey="dados-pessoais" title="Dados Pessoais">
                                <Row>
                                    <Col>
                                        <Form.Label>Nome Completo</Form.Label>
                                        <Form.Control type="text" autoComplete="new-password" value={candidatoAtual.nome} disabled />
                                    </Col>
                                    <Col>
                                        <Form.Label>E-mail</Form.Label>
                                        <Form.Control type="email" autoComplete="new-password" value={candidatoAtual.email} disabled />
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col>
                                        <Form.Label>Data de Nascimento<a className="link-banner">{calcIdade(candidatoAtual.data_nascimento)} Anos</a></Form.Label>
                                        <Form.Control type="text" autoComplete="new-password" value={candidatoAtual.data_nascimento} disabled />
                                    </Col>
                                    <Col>
                                        <Form.Label>Sexo</Form.Label>
                                        <Form.Control type="text" autoComplete="new-password" value={candidatoAtual.sexo} disabled />
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col sm={6}>
                                        <Form.Label>Naturalidade</Form.Label>
                                        <Form.Control type="text" autoComplete="new-password" value={candidatoAtual.naturalidade} disabled />
                                    </Col>
                                    <Col sm={3}>
                                        <Form.Label>Telefone</Form.Label>
                                        <Form.Control type="text" autoComplete="new-password" value={candidatoAtual.telefone} disabled />
                                    </Col>
                                    <Col sm={3}>
                                        <Form.Label>Celular {candidatoAtual.celular && <a onClick={() => { chamarWhatsApp(candidatoAtual.celular) }} className="link-banner">Chamar no WhatsApp</a>}</Form.Label>
                                        <Form.Control type="text" autoComplete="new-password" value={candidatoAtual.celular} disabled />
                                    </Col>
                                </Row>
                            </Tab>
                            <Tab eventKey="endereco" title="Endereço">
                                <Row>
                                    <Col sm={2}>
                                        <Row>
                                            <Col>
                                                <Form.Label>CEP</Form.Label>
                                                <Form.Control type="text" autoComplete="new-password" value={candidatoAtual.cep} disabled />
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col sm={8}>
                                        <Form.Label>Endereço {candidatoAtual.endereco && candidatoAtual.numero && candidatoAtual.cidade && <a onClick={() => { abrirGMaps() }} className="link-banner">Ver Endereço</a>}      {candidatoAtual.endereco && candidatoAtual.numero && candidatoAtual.cidade && <a onClick={() => { rotaGMaps() }} className="link-banner">Calcular Rota</a>}</Form.Label>
                                        <Form.Control type="text" autoComplete="new-password" value={candidatoAtual.endereco} disabled />
                                    </Col>
                                    <Col sm={2}>
                                        <Form.Label>Número</Form.Label>
                                        <Form.Control type="text" autoComplete="new-password" value={candidatoAtual.numero} disabled />
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col>
                                        <Form.Label>Complemento</Form.Label>
                                        <Form.Control type="text" autoComplete="new-password" value={candidatoAtual.complemento} disabled />
                                    </Col>
                                    <Col>
                                        <Form.Label>Bairro</Form.Label>
                                        <Form.Control type="text" autoComplete="new-password" value={candidatoAtual.bairro} disabled />
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col>
                                        <Form.Label>Cidade</Form.Label>
                                        <Form.Control type="text" autoComplete="new-password" value={candidatoAtual.cidade} disabled />
                                    </Col>
                                    <Col>
                                        <Form.Label>Estado</Form.Label>
                                        <Form.Control type="text" autoComplete="new-password" value={candidatoAtual.uf} disabled />
                                    </Col>
                                </Row>
                            </Tab>
                            <Tab eventKey="escolaridade" title="Escolaridade">
                                <Row>
                                    <Col>
                                        <Form.Label>Escolaridade</Form.Label>
                                        <Form.Control type="text" autoComplete="new-password" value={candidatoAtual.escolaridade} disabled />
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col>
                                        <Form.Label>Cursos e Especializações</Form.Label>
                                        <Form.Control as="textarea" style={{ whiteSpace: 'pre-line' }} rows={6} autoComplete="new-password" value={candidatoAtual.cursos} disabled />
                                    </Col>
                                </Row>
                            </Tab>
                            <Tab eventKey="dados-adicionais" title="Dados Adicionais">
                                <Row>
                                    <Col>
                                        <Form.Label>Já Trabalhou Conosco</Form.Label>
                                        <Form.Control type="text" autoComplete="new-password" value={candidatoAtual.trabalhouConosco} disabled />
                                    </Col>
                                    <Col>
                                        <Form.Label>Possui Alguma Deficiência</Form.Label>
                                        <Form.Control type="text" autoComplete="new-password" value={candidatoAtual.deficiencia} disabled />
                                    </Col>
                                    <Col>
                                        <Form.Label>Possui Experiência</Form.Label>
                                        <Form.Control type="text" autoComplete="new-password" value={candidatoAtual.experiencia} disabled />
                                    </Col>
                                </Row>
                            </Tab>
                            <Tab eventKey="experiencia" title="Experiências Profissionais">
                                <Row>
                                    <Col>
                                        <Form.Label>Empresa</Form.Label>
                                        <Form.Control type="text" autoComplete="new-password" value={candidatoAtual.empresa1} disabled />
                                    </Col>
                                    <Col>
                                        <Form.Label>Cargo</Form.Label>
                                        <Form.Control type="text" autoComplete="new-password" value={candidatoAtual.cargo1} disabled />
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col>
                                        <Form.Label>Tempo na Empresa</Form.Label>
                                        <Form.Control type="text" autoComplete="new-password" value={candidatoAtual.tempo1} disabled />
                                    </Col>
                                    <Col>
                                        <Form.Label>Motivo Desligamento</Form.Label>
                                        <Form.Control type="text" autoComplete="new-password" value={candidatoAtual.desligamento1} disabled />
                                    </Col>
                                    <Col>
                                        <Form.Label>Salário</Form.Label>
                                        <Form.Control type="text" autoComplete="new-password" value={candidatoAtual.salario1} disabled />
                                    </Col>
                                </Row>
                                <br />
                                <hr />
                                <Row>
                                    <Col>
                                        <Form.Label>Empresa</Form.Label>
                                        <Form.Control type="text" autoComplete="new-password" value={candidatoAtual.empresa2} disabled />
                                    </Col>
                                    <Col>
                                        <Form.Label>Cargo</Form.Label>
                                        <Form.Control type="text" autoComplete="new-password" value={candidatoAtual.cargo2} disabled />
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col>
                                        <Form.Label>Tempo na Empresa</Form.Label>
                                        <Form.Control type="text" autoComplete="new-password" value={candidatoAtual.tempo2} disabled />
                                    </Col>
                                    <Col>
                                        <Form.Label>Motivo Desligamento</Form.Label>
                                        <Form.Control type="text" autoComplete="new-password" value={candidatoAtual.desligamento2} disabled />
                                    </Col>
                                    <Col>
                                        <Form.Label>Salário</Form.Label>
                                        <Form.Control type="text" autoComplete="new-password" value={candidatoAtual.salario2} disabled />
                                    </Col>
                                </Row>
                                <br />
                                <hr />
                                <Row>
                                    <Col>
                                        <Form.Label>Empresa</Form.Label>
                                        <Form.Control type="text" autoComplete="new-password" value={candidatoAtual.empresa3} disabled />
                                    </Col>
                                    <Col>
                                        <Form.Label>Cargo</Form.Label>
                                        <Form.Control type="text" autoComplete="new-password" value={candidatoAtual.cargo3} disabled />
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col>
                                        <Form.Label>Tempo na Empresa</Form.Label>
                                        <Form.Control type="text" autoComplete="new-password" value={candidatoAtual.tempo3} disabled />
                                    </Col>
                                    <Col>
                                        <Form.Label>Motivo Desligamento</Form.Label>
                                        <Form.Control type="text" autoComplete="new-password" value={candidatoAtual.desligamento3} disabled />
                                    </Col>
                                    <Col>
                                        <Form.Label>Salário</Form.Label>
                                        <Form.Control type="text" autoComplete="new-password" value={candidatoAtual.salario3} disabled />
                                    </Col>
                                </Row>
                            </Tab>
                            <Tab eventKey="entrevista" title="Entrevista">
                                <Tabs defaultActiveKey="dados-entrevista" id="tab-entrevista" className="mb-3">
                                    <Tab eventKey="dados-entrevista" title="Dados da Entrevista">
                                        <Row>
                                            <Col sm={4}>
                                                <Form.Label>Entrevistador</Form.Label>
                                                <Form.Control type="text" autoComplete="new-password" value={candidatoAtual.rh_entrevistador} onChange={(e) => setCandidatoAtual({ ...candidatoAtual, rh_entrevistador: e.target.value })} />
                                            </Col>
                                            <Col sm={2} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                                                <Button variant="success" onClick={() => { IniciaEntrevista() }}>Iniciar Entrevista</Button>
                                            </Col>
                                        </Row>
                                    </Tab>
                                    <Tab eventKey="dados-historia" title="História do Candidato" disabled={!modoEntrevista}>
                                        <Row>
                                            <Col>
                                                <Form.Label>Cidade Onde Nasceu?</Form.Label>
                                                <Form.Control type="text" autoComplete="new-password" value={candidatoAtual.rh_cidade_nasceu} onChange={(e) => setCandidatoAtual({ ...candidatoAtual, rh_cidade_nasceu: e.target.value })} />
                                            </Col>
                                            <Col>
                                                <Form.Label>Onde Mora Atualmente?</Form.Label>
                                                <Form.Control type="text" autoComplete="new-password" value={candidatoAtual.rh_mora_atualmente} onChange={(e) => setCandidatoAtual({ ...candidatoAtual, rh_mora_atualmente: e.target.value })} />
                                            </Col>
                                            <Col>
                                                <Form.Label>Com Quem Mora?</Form.Label>
                                                <Form.Control type="text" autoComplete="new-password" value={candidatoAtual.rh_quem_mora} onChange={(e) => setCandidatoAtual({ ...candidatoAtual, rh_quem_mora: e.target.value })} />
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col>
                                                <Form.Label>Casa Própria ou Alugada?</Form.Label>
                                                <Form.Control type="text" autoComplete="new-password" value={candidatoAtual.rh_tipo_moradia} onChange={(e) => setCandidatoAtual({ ...candidatoAtual, rh_tipo_moradia: e.target.value })} />
                                            </Col>
                                            <Col>
                                                <Form.Label>Quanto Tempo Está na Cidade?</Form.Label>
                                                <Form.Control type="text" autoComplete="new-password" value={candidatoAtual.rh_tempo_cidade} onChange={(e) => setCandidatoAtual({ ...candidatoAtual, rh_tempo_cidade: e.target.value })} />
                                            </Col>
                                            <Col>
                                                <Form.Label>Qual Motivo da Mudança?</Form.Label>
                                                <Form.Control type="text" autoComplete="new-password" value={candidatoAtual.rh_motivo_mudanca} onChange={(e) => setCandidatoAtual({ ...candidatoAtual, rh_motivo_mudanca: e.target.value })} />
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col>
                                                <Form.Label>Grau de Escolaridade?</Form.Label>
                                                <Form.Control type="text" autoComplete="new-password" value={candidatoAtual.rh_grau_escolaridade} onChange={(e) => setCandidatoAtual({ ...candidatoAtual, rh_grau_escolaridade: e.target.value })} />
                                            </Col>
                                            <Col>
                                                <Form.Label>Estado Civil?</Form.Label>
                                                <Form.Control type="text" autoComplete="new-password" value={candidatoAtual.rh_estado_civil} onChange={(e) => setCandidatoAtual({ ...candidatoAtual, rh_estado_civil: e.target.value })} />
                                            </Col>
                                            <Col>
                                                <Form.Label>Cônjuge Trabalha? O que Faz? Onde?</Form.Label>
                                                <Form.Control type="text" autoComplete="new-password" value={candidatoAtual.rh_conjuge_trabalha} onChange={(e) => setCandidatoAtual({ ...candidatoAtual, rh_conjuge_trabalha: e.target.value })} />
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col>
                                                <Form.Label>Tem Filhos? Quantos? Qual Idade?</Form.Label>
                                                <Form.Control type="text" autoComplete="new-password" value={candidatoAtual.rh_filhos} onChange={(e) => setCandidatoAtual({ ...candidatoAtual, rh_filhos: e.target.value })} />
                                            </Col>
                                            <Col>
                                                <Form.Label>Quem Cuida dos Filhos?</Form.Label>
                                                <Form.Control type="text" autoComplete="new-password" value={candidatoAtual.rh_quem_cuida_filhos} onChange={(e) => setCandidatoAtual({ ...candidatoAtual, rh_quem_cuida_filhos: e.target.value })} />
                                            </Col>
                                            <Col>
                                                <Form.Label>Conhece Algum Funcionário? É Parente?</Form.Label>
                                                <Form.Control type="text" autoComplete="new-password" value={candidatoAtual.rh_conhece_parente_funcionario} onChange={(e) => setCandidatoAtual({ ...candidatoAtual, rh_conhece_parente_funcionario: e.target.value })} />
                                            </Col>
                                        </Row>

                                    </Tab>
                                    <Tab eventKey="historico-profissional" title="Histórico Profissional" disabled={!modoEntrevista}>
                                    </Tab>
                                    <Tab eventKey="avaliacao-candidato" title="Avaliação do Candidato" disabled={!modoEntrevista}>
                                    </Tab>
                                    <Tab eventKey="observacoes" title="Observações" disabled={!modoEntrevista}>
                                        <Form.Control as="textarea" style={{ whiteSpace: 'pre-line' }} rows={8} autoComplete="new-password" value={candidatoAtual.rh_observacoes} onChange={(e) => setCandidatoAtual({ ...candidatoAtual, rh_observacoes: e.target.value })} />
                                    </Tab>
                                </Tabs>
                            </Tab>
                        </Tabs>

                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="success" onClick={() => { AtualizaCandidato() }}>Salvar</Button>
                    </Modal.Footer>
                </Modal>



            </Container>
        </>

    )
}

export default Candidatos;