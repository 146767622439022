import React, { useState } from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import { RiAdminFill } from 'react-icons/ri'

import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'

import { msgAviso } from "../components/sweet";

function Logar() {

    const [usuario, setUsuario] = useState('')
    const [senha, setSenha] = useState('')

    const [loading, setLoading] = useState(false)

    const navigate = useNavigate()

    function Acessar() {
        setLoading(true)
        console.log('Logando...')
        const auth = getAuth();
        signInWithEmailAndPassword(auth, usuario, senha)
            .then((userCredential) => {
                setLoading(false)
                localStorage.setItem('logado', 'S')
                const user = userCredential.user;
                console.log('Logado com Sucesso!')
                console.log('Email: ' + user.email)
                navigate("/intranet/dashboard")
            })
            .catch((error) => {
                setLoading(false)
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log('Erro: ' + errorCode + ' - ' + errorMessage)
                msgAviso('Atenção', 'Acesso Negado', 'error', 'ok');
            })
    }

    return (

        <div className="pagina">
            <div className='box-login'>
                <div className='box-login-titulo'>
                    <h1>Administração</h1>
                    <br />
                    <div className='centraliza'>
                        < RiAdminFill
                            size={128}
                            color='#215497'
                        />
                    </div>
                </div>
                <div className='box-login-inputs'>
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>E-mail</Form.Label>
                            <Form.Control type="email" placeholder="E-mail" value={usuario} onChange={(e) => { setUsuario(e.target.value) }} />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Senha</Form.Label>
                            <Form.Control type="password" placeholder="Senha" value={senha} onChange={(e) => { setSenha(e.target.value) }} />
                        </Form.Group>

                        <div className='box-botao-acessar'>
                            <Button variant="primary" type="button" onClick={() => { Acessar() }}>
                                {!loading && 'Acessar'}
                                {loading &&
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />

                                }
                            </Button>
                        </div>

                    </Form>
                </div>
                <div className="box-voltar-site">
                    <p onClick={() => { navigate("/") }}>Voltar ao Site</p>
                </div>
            </div>

        </div>

    );
}

export default Logar;
