import React, { useState, useEffect } from 'react'
import { getAuth, createUserWithEmailAndPassword, deleteUser } from "firebase/auth";


import { Container, Button, Spinner, Row, Col, Modal, Form } from 'react-bootstrap'
import DataTable from 'react-data-table-component';

import { collection, getDocs, query, where, deleteDoc, doc, updateDoc, addDoc } from "firebase/firestore";
import { db } from "../../components/firebase";

import { msgAviso } from "../../components/sweet";

import Navegacao from "../../components/navbar";




function Usuarios() {

    const [usuarios, setUsuarios] = useState([])
    const [loadingTable, setLoadingTable] = useState([])

    const [modalCadUsuario, setModalCadUsuario] = useState(false)
    const [modoEdicao, setModoEdicao] = useState(false)

    const [idClicado, setIdClicado] = useState('')
    const [nomeUsuario, setNomeUsuario] = useState('')
    const [emailUsuario, setEmailUsuario] = useState('')
    const [senhaUsuario, setSenhaUsuario] = useState('')

    // function identificaUsuario() {
    //     const auth = getAuth();
    //     const user = auth.currentUser;
    //     if (user !== null) {

    //         const displayName = user.displayName;
    //         const email = user.email;
    //         const photoURL = user.photoURL;
    //         const emailVerified = user.emailVerified;

    //         console.log('Usuário Logado: ' + email)

    //         const uid = user.uid;
    //     }
    // }

    async function criarUsuarioFirebase() {
        const auth = getAuth();
        createUserWithEmailAndPassword(auth, emailUsuario, senhaUsuario)
            .then((userCredential) => {
                const user = userCredential.user;
                console.log('Usuário Firebase Criado Com Sucesso!' + user)
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.error('Falha ao Criar Usuário no Firebase: ' + errorCode + ' - ' + errorMessage)
            });
    }

    async function deletarUsuarioFirebase() {
        const auth = getAuth();
        const user = auth.currentUser;

        deleteUser(user).then(() => {
            console.log('Usuário Firebase Deletado Com Sucesso!')
        }).catch((error) => {
            console.error('Falha ao Deletar Usuário no Firebase: ' + error.message)
        });
    }

    async function CarregaUsuarios() {

        setLoadingTable(true)
        const getDados = await getDocs(collection(db, "usuarios"))
        setUsuarios([])
        getDados.forEach((doc) => {
            setUsuarios(dados => dados.concat({ ...doc.data(), id: doc.id }))
            console.log(doc.data())
            setLoadingTable(false)
        }
        )
        setLoadingTable(false)
    };

    function validateEmail(email) {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    async function buscaDados(emailDigitado) {
        const q = query(collection(db, "usuarios"), where("email", "==", emailDigitado));

        const querySnapshot = await getDocs(q);
        console.log('Tamanho: ' + querySnapshot.size)
        return (
            querySnapshot.size
        )
    };

    async function cadastrarUsuario() {
        try {

            // setLoading(true)

            if (!validateEmail(emailUsuario)) {
                msgAviso('Atenção', 'E-mail Inválido', 'error', 'ok');
            } else {

                const jaExiste = await buscaDados(emailUsuario)

                if (jaExiste === 0) {

                    var data = new Date();
                    var dataAtual = String(data.getDate()).padStart(2, '0') + '/' + String(data.getMonth() + 1).padStart(2, '0') + '/' + data.getFullYear();

                    const docRef = await addDoc(collection(db, "usuarios"), {
                        nome: nomeUsuario,
                        email: emailUsuario,
                        senha: senhaUsuario,
                        datacad: dataAtual,
                    });
                    console.log("Document written with ID: ", docRef.id);
                    criarUsuarioFirebase()
                    setNomeUsuario('')
                    setEmailUsuario('')
                    setSenhaUsuario('')
                    setModalCadUsuario(false)
                    msgAviso('Parabéns', 'Usuário Cadastrado com Sucesso!', 'success', 'ok');
                    CarregaUsuarios()
                } else {
                    msgAviso('Atenção', 'E-mail Já Cadastrado', 'warning', 'ok');
                }
            }
        } catch (e) {
            console.error("Error adding document: ", e);
        }
    }

    async function atualizarUsuario() {
        try {

            // setLoading(true)

            if (!validateEmail(emailUsuario)) {
                msgAviso('Atenção', 'E-mail Inválido', 'error', 'ok');
            } else {

                const userDoc = doc(db, "usuarios", idClicado)
                const newFields = {
                    nome: nomeUsuario,
                    email: emailUsuario,
                    senha: senhaUsuario,
                };
                await updateDoc(userDoc, newFields);

                setNomeUsuario('')
                setEmailUsuario('')
                setSenhaUsuario('')
                setModalCadUsuario(false)
                msgAviso('Parabéns', 'Usuário Atualizado com Sucesso!', 'success', 'ok');
                CarregaUsuarios()

            }
        } catch (e) {
            console.error("Error adding document: ", e);
        }
    }

    async function apagaUsuario() {
        setModalCadUsuario(false)
        const userDoc = doc(db, "usuarios", idClicado)
        await deleteDoc(userDoc)
        deletarUsuarioFirebase()
        CarregaUsuarios()
    }



    useEffect(() => {
        CarregaUsuarios()
    }, [])


    const colunasGridUsuarios = [
        {
            name: 'Nome',
            selector: row => row.nome,
            sortable: true,
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
        },
        {
            name: 'Senha',
            selector: row => row.senha,
            omit: true,
        },
        {
            name: 'Data Cadastro',
            selector: row => row.datacad,
            sortable: true,
        },
    ];

    return (
        <>
            <Navegacao />

            <Container>
                <br />
                <Row>
                    <h1>Usuários</h1>
                </Row>
                <Row>
                    <Col>
                    </Col>
                    <Col>
                        <div className="linha-sem-espaco-direita">
                            <Button variant="success" onClick={() => {
                                setNomeUsuario('')
                                setEmailUsuario('')
                                setSenhaUsuario('')
                                setModoEdicao(false)
                                setModalCadUsuario(true)
                            }} >+</Button>
                        </div>
                    </Col>
                </Row>
                <Row>
                    {loadingTable &&

                        <div className="centraliza">

                            <Spinner
                                as="span"
                                animation="border"
                                size="lg"
                                role="status"
                                aria-hidden="true"
                                variant="primary"
                            />
                        </div>

                    }

                    {!loadingTable &&
                        <DataTable
                            columns={colunasGridUsuarios}
                            data={usuarios}
                            striped={true}
                            highlightOnHover={true}
                            responsive={true}
                            pointerOnHover={true}
                            onRowClicked={(row, index) => {
                                setIdClicado(row.id)
                                setNomeUsuario(row.nome)
                                setEmailUsuario(row.email)
                                setSenhaUsuario(row.senha)
                                setModoEdicao(true)
                                setModalCadUsuario(true)
                            }}
                            noDataComponent={"Nenhum Registro Encontrado =/"}
                        />
                    }
                </Row>

                <Modal //Modal Cadastro Usuário
                    show={modalCadUsuario}

                // dialogClassName="modal-75"
                >
                    <Modal.Header>
                        <Modal.Title>Cadastro de Usuário</Modal.Title>

                    </Modal.Header>

                    <Modal.Body>
                        <Form>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Nome</Form.Label>
                                <Form.Control type="text" placeholder="Nome" value={nomeUsuario} onChange={(e) => { setNomeUsuario(e.target.value) }} />
                            </Form.Group>

                            {!modoEdicao &&
                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Label>E-mail</Form.Label>
                                    <Form.Control type="email" placeholder="E-mail" value={emailUsuario} onChange={(e) => { setEmailUsuario(e.target.value) }} />
                                </Form.Group>
                            }

                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Senha</Form.Label>
                                <Form.Control type="password" placeholder="Senha" value={senhaUsuario} onChange={(e) => { setSenhaUsuario(e.target.value) }} />
                            </Form.Group>

                        </Form>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { setModalCadUsuario(false) }}>Fechar</Button>
                        {modoEdicao &&
                            <Button variant="danger" onClick={() => { apagaUsuario() }}>Excluir</Button>
                        }
                        {!modoEdicao &&
                            <Button variant="success" onClick={() => { cadastrarUsuario() }}>Salvar</Button>
                        }
                        {modoEdicao &&
                            <Button variant="success" onClick={() => { atualizarUsuario() }}>Atualizar</Button>
                        }

                    </Modal.Footer>
                </Modal>

            </Container>
        </>
    )
}

export default Usuarios