import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { collection, getDocs } from "firebase/firestore";

import { db } from "../components/firebase";


function Slides() {

    const [banners, setBanners] = useState([]);
    // const [mostrar, setMostrar] = useState(false)

    useEffect(() => {
        CarregaSlides()
    }, [])

    async function CarregaSlides() {
        console.log('iniciou Busca por Slides')
        const getDados = await getDocs(collection(db, "slides"))
        setBanners([])
        getDados.forEach((doc) => {
            setBanners(banners => banners.concat({ ...doc.data(), id: doc.id }))
            // console.log(doc.data())
        }
        )
        // setMostrar(true)
        console.log('Terminou de Carregar os slides no State')
    };


    function carregaLink(banner) {
        console.log(banner)
        // if (banner === 2) {
        //     console.log('Banner iFood foi Clicado')
        //     window.open('https://www.ifood.com.br/delivery/florianopolis-sc/supermercados-magia-floripa-lagoa-da-conceicao', '_blank');
        // }
        // if (banner === 1) {
        //     console.log('Banner SiteMercado foi Clicado')
        //     window.open('https://www.sitemercado.com.br/supermercadomagia/florianopolis-loja-lagoa-lagoa-da-conceicao-r-manoel-severino-de-oliveira', '_blank');
        // }
    }

    return (
        <Carousel
            showThumbs={false}
            showIndicators={false}
            showStatus={false}
            autoPlay={true}
            infiniteLoop={true}
            transitionTime={500}
            onClickItem={(index) => carregaLink(index)}
            swipeable={true}
            className="slides"
        >

            {
                banners.map(banner => {

                    return (
                        <div key={banner.id}>
                            <img src={banner.imagem} alt={banner.nome} />
                        </div>

                    )
                })
            }


        </Carousel >
    )


}

export default Slides;