import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

import { Button, Container, Modal, Spinner, Form } from 'react-bootstrap'
import DataTable from 'react-data-table-component';

import { collection, getDocs, query, where, deleteDoc, doc, updateDoc, addDoc } from "firebase/firestore";
import { db } from "../../components/firebase";

import { msgAviso } from "../../components/sweet";

import Navegacao from "../../components/navbar";


function Newsletter() {

    const navigate = useNavigate();

    const [newsletter, setNewsletter] = useState([]);

    const [idClicado, setIdClicado] = useState('');
    const [nomeClicado, setNomeClicado] = useState('');
    const [emailClicado, setEmailClicado] = useState('');
    const [telefoneClicado, setTelefoneClicado] = useState('');

    const [newsNome, setNewsNome] = useState('')
    const [newsEmail, setNewsEmail] = useState('')
    const [newsTelefone, setNewsTelefone] = useState('')


    const [loadingTable, setLoadingTable] = useState(false);
    // const [loading, setLoading] = useState(false);

    const [modalCadNews, setModalCadNews] = useState(false);
    const [modalCadEmail, setModalCadEmail] = useState(false);

    function chamarWhatsApp(telefone) {
        const f1 = telefone.replace(/[^0-9]/g, '')
        const formatado = '55' + f1
        const link = 'http://wa.me/' + formatado
        window.open(link, '_blank');
    }

    async function CarregaNewsletter() {

        setLoadingTable(true)
        const getDados = await getDocs(collection(db, "newsletter"))
        setNewsletter([])
        getDados.forEach((doc) => {
            setNewsletter(newsletter => newsletter.concat({ ...doc.data(), id: doc.id }))
            console.log(doc.data())
            setLoadingTable(false)
        }
        )
        setLoadingTable(false)
    };

    async function DeletaEmail() {
        setModalCadNews(false)
        const userDoc = doc(db, "newsletter", idClicado)
        await deleteDoc(userDoc)
        CarregaNewsletter()
    }

    async function AtualizaCadastro() {
        setModalCadNews(false)
        const userDoc = doc(db, "newsletter", idClicado)
        const newFields = { nome: nomeClicado, email: emailClicado };
        await updateDoc(userDoc, newFields);
        CarregaNewsletter()
    }

    async function buscaDados(emailDigitado) {
        const q = query(collection(db, "newsletter"), where("email", "==", emailDigitado));

        const querySnapshot = await getDocs(q);
        console.log('Tamanho: ' + querySnapshot.size)
        return (
            querySnapshot.size
        )
    };

    async function cadastrarNewsletter() {
        try {

            // setLoading(true)

            if (!validateEmail(newsEmail)) {
                msgAviso('Atenção', 'E-mail Inválido', 'error', 'ok');
            } else {

                const jaExiste = await buscaDados(newsEmail)

                if (jaExiste === 0) {

                    var data = new Date();
                    var dataAtual = String(data.getDate()).padStart(2, '0') + '/' + String(data.getMonth() + 1).padStart(2, '0') + '/' + data.getFullYear();

                    const docRef = await addDoc(collection(db, "newsletter"), {
                        nome: newsNome,
                        email: newsEmail,
                        datacad: dataAtual,
                    });
                    console.log("Document written with ID: ", docRef.id);
                    setNewsEmail('')
                    setNewsNome('')
                    setModalCadEmail(false)
                    msgAviso('Parabéns', 'E-mail Cadastrado com Sucesso!', 'success', 'ok');
                    CarregaNewsletter()
                } else {
                    msgAviso('Atenção', 'E-mail Já Cadastrado', 'warning', 'ok');
                }
            }
        } catch (e) {
            console.error("Error adding document: ", e);
        }
    }

    useEffect(() => {
        const logado = localStorage.getItem("logado");
        if (logado !== 'S') {
            navigate("/intranet")
        } else {
            CarregaNewsletter()
        }
    }, [navigate])




    const colunasIndice = [
        {
            name: 'Nome',
            selector: row => row.nome,
            sortable: true,
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
        },
        {
            name: 'Telefone',
            selector: row => row.telefone,
            sortable: true,
        },
        {
            name: 'Data Cadastro',
            selector: row => row.datacad,
            sortable: true,
        },
    ];


    function ExibeModalCadNewsletter(idClicado, nomeClicado, emailClicado, telefoneClicado) {
        setIdClicado(idClicado)
        setNomeClicado(nomeClicado)
        setEmailClicado(emailClicado)
        setTelefoneClicado(telefoneClicado)
        setModalCadNews(true)
    }

    function validateEmail(email) {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }


    return (

        <>
            <Navegacao />

            <Container>
                <br />
                <h1>Newsletter</h1>

                <br />
                <h3>E-mails Cadastrados</h3>
                <div className="linha-sem-espaco-direita">
                    <Button variant="success" onClick={() => { setModalCadEmail(true) }} >+</Button>
                </div>


                {loadingTable &&

                    <div className="centraliza">

                        <Spinner
                            as="span"
                            animation="border"
                            size="lg"
                            role="status"
                            aria-hidden="true"
                            variant="primary"
                        />
                    </div>


                }

                {!loadingTable &&
                    <DataTable
                        columns={colunasIndice}
                        data={newsletter}
                        striped={true}
                        highlightOnHover={true}
                        responsive={true}
                        pointerOnHover={true}
                        onRowClicked={(row, index) => { ExibeModalCadNewsletter(row.id, row.nome, row.email, row.telefone) }}
                        noDataComponent={"Nenhum Registro Encontrado =/"}
                    />
                }




            </Container>

            <Modal //Modal Edita Cadastro
                show={modalCadNews}

            // dialogClassName="modal-75"
            >
                <Modal.Header>
                    <Modal.Title>Edição de Cadastro</Modal.Title>

                </Modal.Header>

                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Nome</Form.Label>
                            <Form.Control type="text" placeholder="Nome" value={nomeClicado} onChange={(e) => { setNomeClicado(e.target.value) }} />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>E-mail</Form.Label>
                            <Form.Control type="email" placeholder="E-mail" value={emailClicado} onChange={(e) => { setEmailClicado(e.target.value) }} />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Telefone {telefoneClicado && <a  onClick={() => { chamarWhatsApp(telefoneClicado) }} className="link-banner">Chamar no WhatsApp</a>}</Form.Label>
                            <Form.Control type="text" placeholder="Telefone" value={telefoneClicado} onChange={(e) => { setTelefoneClicado(e.target.value) }} />
                        </Form.Group>

                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="danger" onClick={() => { DeletaEmail() }}>Excluir</Button>
                    <Button variant="success" onClick={() => { AtualizaCadastro() }}>Salvar</Button>
                    <Button variant="secondary" onClick={() => { setModalCadNews(false) }}>Fechar</Button>
                </Modal.Footer>
            </Modal>

            <Modal //Modal Novo Email
                show={modalCadEmail}

            // dialogClassName="modal-75"
            >
                <Modal.Header>
                    <Modal.Title>Novo Cadastro</Modal.Title>

                </Modal.Header>

                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Nome</Form.Label>
                            <Form.Control type="test" placeholder="Nome" value={newsNome} onChange={(e) => { setNewsNome(e.target.value) }} />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>E-mail</Form.Label>
                            <Form.Control type="email" placeholder="E-mail" value={newsEmail} onChange={(e) => { setNewsEmail(e.target.value) }} />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Telefone</Form.Label>
                            <Form.Control mask="(99) 99999-9999" type="text" placeholder="Telefone" value={newsTelefone} onChange={(e) => { setNewsTelefone(e.target.value) }} />
                        </Form.Group>

                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="danger" onClick={() => { setModalCadEmail(false) }}>Cancelar</Button>
                    <Button variant="success" onClick={() => { (cadastrarNewsletter()) }}>Salvar</Button>
                </Modal.Footer>
            </Modal>


        </>
    );
}

export default Newsletter;
