import React from 'react';

import Menu from '../components/menu';
import Topo from '../components/topo';

function RhLogin() {

    return (



        <div className="App">



            <Topo />

            <Menu />



        </div>
    );
}

export default RhLogin;
