import Menu from '../components/menu';
import Topo from '../components/topo';

function Ofertas() {

    return (

        <div className="App">

            <Topo />

            <Menu />
            <h2>Ofertas em Breve</h2>

        </div>
    );
}

export default Ofertas;
