import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

import { Button, Container, Modal, Spinner, Form, Row, Col } from 'react-bootstrap'
import DataTable from 'react-data-table-component';

import { TiDelete } from 'react-icons/ti'

import Swal from 'sweetalert2'

import { collection, getDocs, addDoc, doc, deleteDoc, updateDoc } from "firebase/firestore";

import { db } from "../../../components/firebase";

import { msgAviso } from "../../../components/sweet";

import Navegacao from "../../../components/navbar";

function CadastroVagas() {

    const navigate = useNavigate();

    const [loadingTable, setLoadingTable] = useState(false);

    const [loading, setLoading] = useState(false);

    const [modoEdicaoVaga, setModoEdicaoVaga] = useState(false);
    const [idEdicao, setIdEdicao] = useState('');

    const [modalCadVaga, setModalCadVaga] = useState(false);
    const [modalModelos, setModalModelos] = useState(false);

    const [vagas, setVagas] = useState([]);
    const [modelos, setModelos] = useState([]);

    const [vagaAtual, setVagaAtual] = useState(
        {
            nome: "",
            descricao: "",
            inicio_vaga: "",
            data_inicio: "",
            data_fim: "",
            faixa_salarial: "",
            status: "",
        }
    );

    async function CadastraVaga() {
        try {

            if (vagaAtual.nome !== '' && vagaAtual.inicio_vaga !== '') {

                setLoading(true)

                var data = new Date();
                var dataAtual = String(data.getDate()).padStart(2, '0') + '/' + String(data.getMonth() + 1).padStart(2, '0') + '/' + data.getFullYear();

                var dataVaga = new Date(vagaAtual.inicio_vaga);
                var format_dataVaga = String(dataVaga.getDate() + 1).padStart(2, '0') + '/' + String(dataVaga.getMonth() + 1).padStart(2, '0') + '/' + dataVaga.getFullYear();

                var dataInicio = new Date(vagaAtual.data_inicio);
                var format_dataInicio = String(dataInicio.getDate() + 1).padStart(2, '0') + '/' + String(dataInicio.getMonth() + 1).padStart(2, '0') + '/' + dataInicio.getFullYear();

                var dataFim = new Date(vagaAtual.data_fim);
                var format_dataFim = String(dataFim.getDate() + 1).padStart(2, '0') + '/' + String(dataFim.getMonth() + 1).padStart(2, '0') + '/' + dataFim.getFullYear();

                const docRef = await addDoc(collection(db, "vagas"), {
                    nome: vagaAtual.nome,
                    descricao: vagaAtual.descricao,
                    inicio_vaga: format_dataVaga,
                    data_inicio: format_dataInicio,
                    data_fim: format_dataFim,
                    faixa_salarial: vagaAtual.faixa_salarial,
                    status: vagaAtual.status,
                    datacad: dataAtual,
                });

                console.log("Document written with ID: ", docRef.id);
                setVagaAtual([])
                setModalCadVaga(false)
                msgAviso('Parabéns', 'Vaga Cadastrada com Sucesso!', 'success', 'ok')
                setLoading(false)
                CarregaVagas()
            } else {
                setLoading(false)
                msgAviso('Atenção', 'Preencha os Campos', 'warning', 'ok')
            }

        } catch (e) {
            setLoading(false)
            console.error("Error adding document: ", e);
            msgAviso('Falha no Processo', e, 'error', 'ok')
        }
    }

    async function CadastraModelo() {
        try {

            if (vagaAtual.nome !== '' && vagaAtual.descricao !== '') {

                setLoading(true)

                var data = new Date();
                var dataAtual = String(data.getDate()).padStart(2, '0') + '/' + String(data.getMonth() + 1).padStart(2, '0') + '/' + data.getFullYear();

                const docRef = await addDoc(collection(db, "modelos_vagas"), {
                    nome: vagaAtual.nome,
                    descricao: vagaAtual.descricao,
                    datacad: dataAtual,
                });

                console.log("Document written with ID: ", docRef.id);
                msgAviso('Parabéns', 'Modelo Salvo com Sucesso!', 'success', 'ok')
                setLoading(false)
            } else {
                setLoading(false)
                msgAviso('Atenção', 'Preencha os Campos Nome e Descrição', 'warning', 'ok')
            }

        } catch (e) {
            setLoading(false)
            console.error("Error adding document: ", e);
            msgAviso('Falha no Processo', e, 'error', 'ok')
        }
    }

    async function CarregaVagas() {

        // setLoadingTable(true)
        const getDados = await getDocs(collection(db, "vagas"))
        setVagas([])
        getDados.forEach((doc) => {
            setVagas(item => item.concat({ ...doc.data(), id: doc.id }))
            console.log(doc.data())
            setLoadingTable(false)
        }
        )
        setLoadingTable(false)
    };

    async function CarregaModelos() {

        setModalCadVaga(false)
        const getDados = await getDocs(collection(db, "modelos_vagas"))
        setModelos([])
        if (getDados.size > 0) {
            getDados.forEach((doc) => {
                setModelos(item => item.concat({ ...doc.data(), id: doc.id }))
                console.log(doc.data())
                setLoadingTable(false)
                setModalModelos(true)
            })
        } else {
            msgAviso('Atenção', 'Nenhum Modelo Localizado', 'info', 'OK');
            setModalModelos(false)
            setModalCadVaga(true)
        }
        setLoadingTable(false)
    };


    async function AtualizaCadVaga() {

        setModalCadVaga(false)

        var dataVaga = new Date(vagaAtual.inicio_vaga);
        var format_dataVaga = String(dataVaga.getDate() + 1).padStart(2, '0') + '/' + String(dataVaga.getMonth() + 1).padStart(2, '0') + '/' + dataVaga.getFullYear();

        var dataInicio = new Date(vagaAtual.data_inicio);
        var format_dataInicio = String(dataInicio.getDate() + 1).padStart(2, '0') + '/' + String(dataInicio.getMonth() + 1).padStart(2, '0') + '/' + dataInicio.getFullYear();

        var dataFim = new Date(vagaAtual.data_fim);
        var format_dataFim = String(dataFim.getDate() + 1).padStart(2, '0') + '/' + String(dataFim.getMonth() + 1).padStart(2, '0') + '/' + dataFim.getFullYear();

        const userDoc = doc(db, "vagas", idEdicao)
        const newFields = {
            nome: vagaAtual.nome,
            descricao: vagaAtual.descricao,
            inicio_vaga: format_dataVaga,
            data_inicio: format_dataInicio,
            data_fim: format_dataFim,
            faixa_salarial: vagaAtual.faixa_salarial,
            status: vagaAtual.status,
        };
        await updateDoc(userDoc, newFields);
        setIdEdicao('')
        CarregaVagas()
    }

    async function DeletaCadVaga() {
        setModalCadVaga(false)
        const userDoc = doc(db, "vagas", idEdicao)
        await deleteDoc(userDoc)
        CarregaVagas()
    }

    async function DeletaModelo(codigo) {
        const userDoc = doc(db, "modelos_vagas", codigo)
        await deleteDoc(userDoc)
        CarregaModelos()
    }

    function LimpaDadosVagaAtual() {
        console.log('Limpando Dados dos Campos')
        vagaAtual.nome = ''
        vagaAtual.descricao = ''
        vagaAtual.inicio_vaga = ''
        vagaAtual.data_inicio = ''
        vagaAtual.data_fim = ''
        vagaAtual.faixa_salarial = ''
        vagaAtual.status = ''
    }


    function FormataStringData(data) {
        var dia = data.split("/")[0];
        var mes = data.split("/")[1];
        var ano = data.split("/")[2];

        return ano + '-' + ("0" + mes).slice(-2) + '-' + ("0" + dia).slice(-2);
    }

    useEffect(() => {
        const logado = localStorage.getItem("logado");
        if (logado !== 'S') {
            navigate("/intranet")
        } else {
            CarregaVagas()
        }
    }, [navigate])

    const colunasQuadroVagas = [
        {
            name: 'Nome',
            selector: row => row.nome,
            sortable: true,
        },
        {
            name: 'Inicio',
            selector: row => row.inicio_vaga,
            sortable: true,
        },
        {
            name: 'Faixa Salárial',
            selector: row => row.faixa_salarial,
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
        },
        {
            name: 'Data Cadastro',
            selector: row => row.datacad,
            omit: true,
        },
        {
            name: 'Descrição',
            selector: row => row.descricao,
            omit: true,
        },
        {
            name: 'Inicio Publicação',
            selector: row => row.data_inicio,
            omit: true,
        },
        {
            name: 'Fim Publicação',
            selector: row => row.data_fim,
            omit: true,
        },
    ];

    const colunasModelos = [
        {
            name: 'Nome',
            selector: row => row.nome,
            sortable: true,
        },
        {
            name: 'Data Cadastro',
            selector: row => row.datacad,
            sortable: true,
        },
        {
            name: '',
            button: true,
            cell: row => (
                < a onClick={() => { DeletaModelo(row.id) }} >
                    <TiDelete size="24" color="#d9534f" title="Excluir Modelo" />
                </a>
            ),
        },
    ];

    return (
        <>
            <Navegacao />
            <Container>
                <br />
                <Row>
                    <h1>Quadro de Vagas</h1>
                </Row>

                <Row>
                    <Col>
                        <div className="linha-sem-espaco-direita">
                            <Button variant="success" title="Criar Nova Vaga" onClick={() => {
                                LimpaDadosVagaAtual()
                                var data = new Date();
                                var dataAtual = String(data.getFullYear() + '-' + String(data.getMonth() + 1).padStart(2, '0') + '-' + String(data.getDate()).padStart(2, '0'));
                                var dataFinal = String(data.getFullYear() + '-' + String(12) + '-' + String(31));
                                setVagaAtual({ ...vagaAtual, inicio_vaga: dataAtual, data_inicio: dataAtual, data_fim: dataFinal })
                                console.log('Vaga Atual: ' + JSON.stringify(vagaAtual))
                                console.log('Data Atual: ' + String(data.getDate()).padStart(2, '0'))
                                console.log('Data Final: ' + dataFinal)
                                setModoEdicaoVaga(false)
                                setModalCadVaga(true)
                            }} >Criar Nova Vaga</Button>
                        </div>
                    </Col>
                </Row>



                <Row>
                    <Col>
                        {loadingTable &&

                            <div className="centraliza">

                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="lg"
                                    role="status"
                                    aria-hidden="true"
                                    variant="primary"
                                />

                            </div>

                        }

                        {!loadingTable &&
                            <DataTable
                                columns={colunasQuadroVagas}
                                data={vagas}
                                striped={true}
                                highlightOnHover={true}
                                responsive={true}
                                pointerOnHover={true}
                                onRowClicked={(row, index) => {

                                    setIdEdicao(row.id)
                                    setVagaAtual({
                                        ...vagaAtual,
                                        nome: row.nome,
                                        descricao: row.descricao,
                                        inicio_vaga: FormataStringData(row.inicio_vaga),
                                        data_inicio: FormataStringData(row.data_inicio),
                                        data_fim: FormataStringData(row.data_fim),
                                        faixa_salarial: row.faixa_salarial,
                                        status: row.status
                                    })
                                    setModoEdicaoVaga(true)
                                    setModalCadVaga(true)

                                }}
                                noDataComponent={"Nenhum Registro Encontrado =/"}
                            />
                        }


                    </Col>
                </Row>

                <Modal //Modal Cadastro Vagas
                    show={modalCadVaga}
                    dialogClassName="modal-50"
                    onHide={() => setModalCadVaga(false)}
                    backdrop="static"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Cadastro de Vaga </Modal.Title>
                    </Modal.Header>


                    <Modal.Body>
                        <Form>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="nome">
                                        <Form.Label>Nome</Form.Label>
                                        <Form.Control type="text" autoComplete="new-password" placeholder="Nome" value={vagaAtual.nome} onChange={(e) => { setVagaAtual({ ...vagaAtual, nome: e.target.value }) }} />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="status">
                                        <Form.Label>Status</Form.Label>
                                        <Form.Select aria-label="Default select example" value={vagaAtual.status} onChange={(e) => { setVagaAtual({ ...vagaAtual, status: e.target.value }) }}>
                                            <option>Selecione</option>
                                            <option value={'ATIVA'}>Ativa</option>
                                            <option value={'INATIVA'}>Inativa</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>

                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="descricao">
                                        <Row>
                                            <Col sm={8}>
                                                <Form.Label>Descrição da Vaga</Form.Label>
                                            </Col>
                                            <Col sm={2}>
                                                {!modoEdicaoVaga && <a onClick={() => { CarregaModelos() }} className="link-banner">Carregar</a>}
                                            </Col>
                                            <Col sm={2}>
                                                {!modoEdicaoVaga && <a onClick={() => { CadastraModelo() }} className="link-banner">Salvar</a>}
                                            </Col>
                                        </Row>

                                        <Form.Control as="textarea" style={{ whiteSpace: 'pre-line' }} rows={3} placeholder="Descreva um Resumo da Vaga" value={vagaAtual.descricao} onChange={(e) => { setVagaAtual({ ...vagaAtual, descricao: e.target.value }) }} />

                                    </Form.Group>

                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="faixa_salarial">
                                        <Form.Label>Faixa Salárial</Form.Label>
                                        <Form.Select aria-label="Default select example" value={vagaAtual.faixa_salarial} onChange={(e) => { setVagaAtual({ ...vagaAtual, faixa_salarial: e.target.value }) }}>
                                            <option>Selecione</option>
                                            <option value={'Entre R$ 1.000 a R$ 1.999'}>Entre R$ 1.000 a R$ 1.999</option>
                                            <option value={'Entre R$ 2.000 a R$ 2.999'}>Entre R$ 2.000 a R$ 2.999</option>
                                            <option value={'Entre R$ 3.000 a R$ 3.999'}>Entre R$ 3.000 a R$ 3.999</option>
                                            <option value={'A Combinar'}>A Combinar</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="inicio_vaga">
                                        <Form.Label>Inicio da Vaga</Form.Label>
                                        <Form.Control type="date" placeholder="Inicio da Vaga" value={vagaAtual.inicio_vaga} onChange={(e) => { setVagaAtual({ ...vagaAtual, inicio_vaga: e.target.value }) }} />
                                    </Form.Group>
                                </Col>

                            </Row>

                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="inicio_publi">
                                        <Form.Label>Inicio Publicação</Form.Label>
                                        <Form.Control type="date" placeholder="Inicio da Vaga" value={vagaAtual.data_inicio} onChange={(e) => { setVagaAtual({ ...vagaAtual, data_inicio: e.target.value }) }} />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="final_publi">
                                        <Form.Label>Fim Publicação</Form.Label>
                                        <Form.Control type="date" placeholder="Inicio da Vaga" value={vagaAtual.data_fim} onChange={(e) => { setVagaAtual({ ...vagaAtual, data_fim: e.target.value }) }} />
                                    </Form.Group>
                                </Col>
                            </Row>

                        </Form>
                    </Modal.Body>

                    <Modal.Footer>
                        {!loading && !modoEdicaoVaga &&
                            <Button variant="success" onClick={() => { CadastraVaga() }}>Salvar</Button>
                        }

                        {!loading && modoEdicaoVaga &&
                            <>
                                <Button variant="danger" onClick={() => {

                                    Swal.fire({
                                        title: 'Confirma Exclusão da Vaga?',
                                        text: 'Você deseja realmente excluir esta vaga? Esta ação não poderá ser desfeita.',
                                        showCancelButton: true,
                                        icon: 'question',
                                        cancelButtonText: 'Cancelar',
                                        confirmButtonText: 'Confirmar',
                                        confirmButtonColor: '#d9534f',
                                    }).then((result) => {
                                        /* Read more about isConfirmed, isDenied below */
                                        if (result.isConfirmed) {
                                            DeletaCadVaga()
                                        } else if (result.isDenied) {
                                            Swal.fire('Changes are not saved', '', 'info')
                                        }
                                    })


                                }}>Excluir</Button>
                                <Button variant="success" onClick={() => { AtualizaCadVaga() }}>Atualizar</Button>
                            </>

                        }
                        {loading &&
                            <Button variant="success">
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    variant="light"
                                />
                            </Button>
                        }
                    </Modal.Footer>
                </Modal>


                <Modal //Modal Modelos
                    show={modalModelos}
                    onHide={() => {
                        setModalModelos(false)
                        setModalCadVaga(true)
                    }}
                    backdrop="static"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Modelos</Modal.Title>

                    </Modal.Header>

                    <Modal.Body>
                        <DataTable
                            columns={colunasModelos}
                            data={modelos}
                            striped={true}
                            highlightOnHover={true}
                            responsive={true}
                            pointerOnHover={true}
                            onRowClicked={(row, index) => {

                                setVagaAtual({
                                    ...vagaAtual,
                                    nome: row.nome,
                                    descricao: row.descricao,
                                })
                                setModalModelos(false)
                                setModalCadVaga(true)

                            }}
                            noDataComponent={"Nenhum Registro Encontrado =/"}
                        />
                    </Modal.Body>

                    {/* <Modal.Footer>
                        <Button variant="success" onClick={() => { (cadastrarNewsletter()) }}>Salvar</Button>
                    </Modal.Footer> */}
                </Modal>


            </Container>
        </>

    )
}

export default CadastroVagas;