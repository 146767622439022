import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import React from "react";
import { BrowserRouter, Route, Routes } from 'react-router-dom';


import Home from './pages/Home';
import Ofertas from './pages/Ofertas';
import TrabalheConosco from './pages/Trabalhe-conosco';
import RhLogin from './pages/rh-login';
import Desconhecido from './pages/404';
import Logar from './pages/Logar';
import Dashboard from './pages/intranet/Dashboard';
import Newsletter from './pages/intranet/Newsletter';
import Banners from './pages/intranet/Banners';
import CadastroVagas from './pages/intranet/recrutamento/cadastro-vagas';
import Candidatos from './pages/intranet/recrutamento/candidatos';
import Usuarios from './pages/intranet/Usuarios';
import PaginaIdentificacao from './pages/Identificacao';


function App() {

  return (

    <BrowserRouter>

      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/ofertas" element={<Ofertas />} />
        <Route exact path="/intranet" element={<Logar />} />
        <Route exact path="/trabalhe-conosco" element={<TrabalheConosco />} />
        <Route path="identificacao" element={<PaginaIdentificacao />}>
          <Route path=":id" element={<PaginaIdentificacao />} />
        </Route>
        <Route exact path="/rh-meu-cadastro" element={<RhLogin />} />
        <Route exact path="/intranet/dashboard" element={<Dashboard />} />
        <Route exact path="/intranet/newsletter" element={<Newsletter />} />
        <Route exact path="/intranet/usuarios" element={<Usuarios />} />
        <Route exact path="/intranet/banners" element={<Banners />} />
        <Route exact path="/intranet/recrutamento/cadastro-vagas" element={<CadastroVagas />} />
        <Route exact path="/intranet/recrutamento/candidatos" element={<Candidatos />} />
        <Route path="*" element={<Desconhecido />} />
      </Routes>



    </BrowserRouter>
  );
}

export default App;
