import React, { useState } from "react";

function Topo() {

    return (

        <div className="topo">
            <div className="principal-topo">
                <div className="logo">

                    <a href="/" rel="noreferrer"><img src="https://magiafloripa.com.br/assets/logo.png" alt="logo" /></a>

                </div>
                <div className="info-topo">
                    <div className="info-dados">

                        <>
                            <a href="https://wa.me/554832151900" target="_blank" rel="noreferrer"><img src="https://magiafloripa.com.br/assets/whatsapp.png" alt="logo whatsapp" /></a>
                            <a href="https://wa.me/554832151900" target="_blank" rel="noreferrer"><h1>(48) 3215-1900</h1></a>
                        </>


                    </div>


                </div>
            </div>



        </div >
    )

}

export default Topo;