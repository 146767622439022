import React from 'react';


import Slides from '../components/slides';
import Menu from '../components/menu';
import Topo from '../components/topo';
import Footer from '../components/footer';

import { Button, Spinner } from 'react-bootstrap'

import InputMask from 'react-input-mask';
import FloatingWhatsApp from "react-floating-whatsapp"

import { useState } from 'react';

import { collection, addDoc, getDocs, query, where } from "firebase/firestore";
import { db } from '../components/firebase'

import { msgAviso } from '../components/sweet';


function Home() {

    const [newsNome, setNewsNome] = useState('')
    const [newsEmail, setNewsEmail] = useState('')
    const [newsTelefone, setNewsTelefone] = useState('')

    const [loading, setLoading] = useState(false)

    async function buscaDados(emailDigitado) {
        const q = query(collection(db, "newsletter"), where("email", "===", emailDigitado));

        const querySnapshot = await getDocs(q);
        console.log('Tamanho: ' + querySnapshot.size)
        return (
            querySnapshot.size
        )
    };

    function validateEmail(email) {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    async function cadastrarNewsletter() {
        try {

            if (!validateEmail(newsEmail)) {
                msgAviso('Atenção', 'E-mail Inválido', 'error', 'ok');
            } else {

                setLoading(true)

                const jaExiste = await buscaDados(newsEmail)

                if (jaExiste === 0) {

                    var data = new Date();
                    var dia = String(data.getDate()).padStart(2, '0');
                    var mes = String(data.getMonth() + 1).padStart(2, '0');
                    var ano = data.getFullYear();
                    var dataAtual = dia + '/' + mes + '/' + ano;

                    const docRef = await addDoc(collection(db, "newsletter"), {
                        nome: newsNome,
                        email: newsEmail,
                        telefone: newsTelefone,
                        datacad: dataAtual,
                    });
                    console.log("Document written with ID: ", docRef.id);
                    setNewsEmail('')
                    setNewsNome('')
                    setNewsTelefone('')
                    setLoading(false)
                    msgAviso('Parabéns', 'E-mail Cadastrado com Sucesso!', 'success', 'ok');
                } else {
                    setLoading(false)
                    setNewsEmail('')
                    setNewsNome('')
                    setNewsTelefone('')
                    msgAviso('Atenção', 'E-mail Já Cadastrado', 'warning', 'ok');
                }
            }
        } catch (e) {
            console.error("Error adding document: ", e);
        }
    }

    return (

        <div className="App">

            <Topo />

            <Menu />

            <Slides />

            <div className="conteudo">

                <div className="linha">

                    <div className="box-newsletter">
                        <h3>Conheça nossos serviços</h3>
                        <ul id="lista-servicos">
                            {/* <li className="item-servico">Recarga de Celular</li> */}
                            <li className="item-servico">Estacionamento</li>
                            <li className="item-servico">Entregas</li>
                        </ul>

                    </div>

                    <div className="box-newsletter">
                        <h3>Receba nossas ofertas por e-mail</h3>

                        <form>

                            <div className='linha-form'>
                                <input type="text" placeholder="Nome" value={newsNome} onChange={e => setNewsNome(e.target.value)} ></input>
                                <InputMask mask="(99) 99999-9999" type="text" placeholder="Telefone (WhatsApp)" value={newsTelefone} onChange={e => setNewsTelefone(e.target.value)} ></InputMask>
                            </div>
                            <div className='linha-form'>
                                <input type="text" placeholder="Email" value={newsEmail} onChange={e => setNewsEmail(e.target.value)} ></input>
                            </div>

                            <div className="linha-box-news">

                                {/* <button onClick={cadastrarNewsletter} type="button" id="botao-cadastrar-news">Cadastrar</button> */}

                                {loading &&
                                    <Button variant="primary" onClick={() => { cadastrarNewsletter() }}><Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        variant="light"
                                    /></Button>
                                }

                                {!loading &&
                                    <Button variant="primary" onClick={() => { cadastrarNewsletter() }}>Cadastrar</Button>
                                }


                            </div>
                        </form>
                    </div>

                </div>

                <div className='linha-sem-espaco'>

                    <div className="box-1c">
                        <h3>✓ PRODUTO DIRETO DO PRODUTOR</h3>
                        <p>Trabalhamos com produtos selecionados e diretos do produtor para a sua mesa.
                        </p>
                        <hr />
                        <br /><br />

                        <h3>✓ AÇOUGUE COM CARNES NOBRES</h3>
                        <p>Trabalhamos com as maiores redes distribuidoras de carnes do país para garantir que nossos produtos cheguem ainda frescos.
                        </p>
                        <hr />
                        <br /><br />

                        <h3>✓ PADARIA COM FABRICAÇÃO PRÓPRIA</h3>
                        <p>Em nossa padaria você sempre encontrará uma diversidade de pães, salgados, tortas, bolos e doces para o seu café.
                        </p>
                        <hr />
                    </div>


                </div>
            </div>

            <FloatingWhatsApp
                phoneNumber="554832151900"
                accountName="Magia Floripa"
                allowClickAway
                statusMessage="Normalmente responde dentro de 1 hora"
                placeholder="Digite a Mensagem"
                chatMessage="Em Que Podemos Ajudar?"
                avatar='./assets/whatsapp_perfil.png'
            />

            <Footer />

        </div>


    );
}

export default Home;
